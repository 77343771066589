import Head from 'next/head'
import Link from 'next/link'
import { withAuthUser, AuthAction } from 'next-firebase-auth'
import FullPageLoader from '../full-page-loader'
import NotificationsTray from '../notifications-tray'
import { Layout, Drawer, notification, Avatar, Breadcrumb, Dropdown, Menu, Badge, Button } from 'antd'
import { UserOutlined, LogoutOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import styles from './Page.module.css'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import DeliveryPlanFilterModal from '../delivery-plan-filter-modal'
import SalesOrderFilterModal from '../sales-order-filter-modal'
import useSearchParams from '../../hooks/useSearchParams'
import ProductFilterModal from '../product-filter-modal'
import DistributorFilterModal from '../distributor-filter-modal'
import { signOut } from '../../store/auth/actions'
import InventoryFilterModal from '../inventory-filter-modal'
import VehicleFilterModal from '../vehicle-filter-modal'
import UserFilterModal from '../user-filter-modal'
import { InventoryProductView, claimsRoles, paths } from '../../utils/constants'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { isEmptyObject, isNotEmpty } from '../../utils'
import ReportFilterModal from '../report-filter-modal'
import { SALES_ORDER_REQUESTED_STATUS } from '../../pages/sales-orders'
import InventorySkuFilterModal from '../inventory-sku-filter-modal'
import InventoryOnHandStockFilterModal from '../inventory-on-hand-stock-filter-modal'
import InventoryOrderMovementFilterModal from '../inventory-order-movement-filter-modal'
import InventoryScanAdherenceFilterModal from '../inventory-scan-adherence-filter-modal'
import InventorySummaryFilterModal from '../inventory-summary-filter-modal'
import useInventoryActions from '../../hooks/useInventoryActions'

const { Header, Content } = Layout

const userMenuItems = [
	{
		icon: '/img/home.svg',
		title: 'Home',
		path: paths.HOME
	},
	{
		icon: '/img/distributors.svg',
		title: 'Dealers',
		path: paths.DISTRIBUTORS
	},
	{
		icon: '/img/products.svg',
		title: 'Products',
		path: paths.PRODUCTS
	},
	{
		icon: '/img/truck.svg',
		title: 'Fleet',
		path: paths.FLEET
	},
	{
		icon: '/img/bag.svg',
		title: 'Purchase',
		path: paths.PURCHASE_ORDERS
	},
	{
		icon: '/img/inventory.svg',
		title: 'Inventory',
		path: paths.INVENTORY_PRODUCTS
	},
	{
		icon: '/img/inventory.svg',
		title: 'Generate Labels',
		path: paths.GENERATE_LABELS
	},
	{
		icon: '/img/cart.svg',
		title: 'Vendors',
		path: paths.VENDORS
	},
	{
		icon: '/img/location.svg',
		title: 'Locations',
		path: paths.LOCATIONS
	},
	{
		icon: '/img/reports.svg',
		title: 'Reports',
		path: paths.REPORTS
	},
	{
		icon: '/img/lock.svg',
		title: 'Access',
		children: [
			{
				title: 'Permission Groups',
				path: paths.PERMISSIONS
			},
			{
				title: 'Users',
				path: paths.USERS
			}
		]
	}
]

const adminMenuItems = [
	{
		icon: '/img/distributors.svg',
		title: 'Companies',
		path: paths.COMPANIES
	},
	{
		icon: '/img/users.svg',
		title: 'Users',
		path: paths.USERS
	}
]

const tabs = [
	{
		title: 'Dashboard',
		path: paths.DASHBOARD
	},
	{
		title: 'Orders',
		path: paths.SALES_ORDERS
	},
	{
		title: 'Delivery Plans',
		path: paths.DELIVERY_PLANS
	}
]

const Page = ({ children, parentTitle, childTitle, titleComponent, fullPage, parentLink }) => {
	const router = useRouter()
	const dispatch = useDispatch()
	const { role, companyDetails, permissions } = useSelector(state => state.authReducer)
	const [logoUrl, setLogoUrl] = useLocalStorage('logoUrl', '')
	const { unreadCount } = useSelector(state => state.notificationsReducer)
	const [isMenuVisible, setIsMenuVisible] = useState(false)
	const [currentTab, setCurrentTab] = useState()
	const [isDeliveryPlanFilterModalVisible, setIsDeliveryPlanFilterModalVisible] = useState(false)
	const [isSalesOrderFilterModalVisible, setIsSalesOrderFilterModalVisible] = useState(false)
	const [isProductFilterModalVisible, setIsProductFilterModalVisible] = useState(false)
	const [isInventoryFilterModalVisible, setIsInventoryFilterModalVisible] = useState(false)
	const [isInventorySkuFilterModalVisible, setIsInventorySkuFilterModalVisible] = useState(false)
	const [isInventoryOnHandStockFilterModalVisible, setIsInventoryOnHandStockFilterModalVisible] = useState(false)
	const [isInventoryOrderMovementFilterModalVisible, setIsInventoryOrderMovementFilterModalVisible] = useState(false)
	const [isInventoryScanAdherenceFilterModalVisible, setIsInventoryScanAdherenceFilterModalVisible] = useState(false)
	const [isDistributorFilterModalVisible, setIsDistributorFilterModalVisible] = useState(false)
	const [isVehicleFilterModalVisible, setIsVehicleFilterModalVisible] = useState(false)
	const [isReportFilterModalVisible, setIsReportFilterModalVisible] = useState(false)
	const [isUserFilterModalVisible, setIsUserFilterModalVisible] = useState(false)
	const [isDeliveryPlanEnabled, setIsDeliveryPlanEnabled] = useState(false)
	const [isPurchaseOrderEnabled, setIsPurchaseOrderEnabled] = useState(false)
	const [isFleetEnabled, setIsFleetEnabled] = useState(true)
	const [isVendorEnabled, setIsVendorEnabled] = useState(true)
	const [isInventorySummaryFilterModalVisible, setIsInventorySummaryFilterModalVisible] = useState(false)
	const [isInventoryEnabled, setIsInventoryEnabled] = useState(true)
	const [currentExpandedMenuItem, setCurrentExpandedMenuItem] = useState()
	const { isSearchApplied, clearSearchParams } = useSearchParams()
	const { viewType } = useInventoryActions()
	const { pathname, query } = router

	useEffect(() => {
		if (pathname === tabs[0].path) {
			// Dashboard
			setCurrentTab(tabs[0].path)
		} else if (tabs[1].path.includes(pathname)) {
			// Sales Orders
			setCurrentTab(tabs[1].path)
		} else if (pathname.includes(tabs[2].path)) {
			// Delivery Plans
			setCurrentTab(tabs[2].path)
		} else {
			setCurrentTab(null)
		}
	}, [pathname])

	useEffect(() => {
		if (companyDetails) {
			setIsDeliveryPlanEnabled(companyDetails.isDeliveryPlanEnabled)
			setIsPurchaseOrderEnabled(companyDetails.isPurchaseOrderEnabled)
			setIsFleetEnabled(companyDetails.isFleetEnabled)
			setIsVendorEnabled(companyDetails.isVendorEnabled)
			setIsInventoryEnabled(companyDetails.isInventoryEnabled)
			setLogoUrl(companyDetails.logoUrl)
		}
	}, [companyDetails])

	const logout = async () => {
		try {
			await firebase.auth().signOut()
			localStorage.removeItem('logoUrl')
			dispatch(signOut())
		} catch (e) {
			notification.open({
				message: 'Logout Error',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const onSearchIconClick = () => {
		if (pathname.includes(paths.DELIVERY_PLANS)) {
			setIsDeliveryPlanFilterModalVisible(true)
		} else if (pathname.includes(paths.SALES_ORDERS)) {
			setIsSalesOrderFilterModalVisible(true)
		} else if (pathname.includes(paths.PRODUCTS)) {
			setIsProductFilterModalVisible(true)
		} else if (pathname.includes(paths.DISTRIBUTORS)) {
			setIsDistributorFilterModalVisible(true)
		} else if (pathname.includes(paths.INVENTORY)) {
			if (isEmptyObject(query)) {
				if (viewType === InventoryProductView.SUMMARY) {
					setIsInventorySummaryFilterModalVisible(true)
				} else {
					setIsInventoryFilterModalVisible(true)
				}
			} else if (query.sku) {
				setIsInventorySkuFilterModalVisible(true)
			} else if (query.onHandStock) {
				setIsInventoryOnHandStockFilterModalVisible(true)
			} else if (query.orderMovement) {
				setIsInventoryOrderMovementFilterModalVisible(true)
			} else if (query.scanAdherence) {
				setIsInventoryScanAdherenceFilterModalVisible(true)
			}
		} else if (pathname.includes(paths.FLEET)) {
			setIsVehicleFilterModalVisible(true)
		} else if (pathname.includes(paths.USERS)) {
			setIsUserFilterModalVisible(true)
		} else if (pathname.includes(paths.REPORTS)) {
			setIsReportFilterModalVisible(true)
		}
	}

	const isSearchIconVisible = () => {
		return role && role !== claimsRoles.ADMIN && (
			pathname.includes(paths.SALES_ORDERS) ||
			pathname.includes(paths.DELIVERY_PLANS) ||
			pathname.includes(paths.PRODUCTS) ||
			pathname.includes(paths.DISTRIBUTORS) ||
			(pathname === paths.INVENTORY_PRODUCTS && (isEmptyObject(query) || query.sku || query.onHandStock || query.orderMovement || query.scanAdherence)) ||
			pathname.includes(paths.FLEET) ||
			pathname.includes(paths.USERS) ||
			pathname.includes(paths.REPORTS)
		)
	}

	const Title = titleComponent
	const menuItems = role === claimsRoles.ADMIN ? adminMenuItems : userMenuItems
	const modifiedTabs = useMemo(() => {
		const newTabs = [...tabs]
		const orderStatusFlow = companyDetails?.orderStatusFlow
		const defaultOrderStatus = orderStatusFlow ? orderStatusFlow.statuses[0].toLowerCase() : SALES_ORDER_REQUESTED_STATUS
		newTabs[1].path = `${paths.SALES_ORDERS}?status=${defaultOrderStatus}`
		return newTabs.filter(tab => {
			if (tab.path === paths.DELIVERY_PLANS && (isDeliveryPlanEnabled === false || !permissions.viewDeliveryPlans)) {
				return false
			}
			if (tab.path === paths.PURCHASE_ORDERS && (isPurchaseOrderEnabled === false || !permissions.viewPurchaseOrders)) {
				return false
			}
			if (tab.path.includes(paths.SALES_ORDERS) && !permissions.viewOrders) {
				return false
			}
			return true
		})
	}, [permissions, companyDetails, isDeliveryPlanEnabled, isPurchaseOrderEnabled])

	const modifiedMenuItems = useMemo(() => {
		const newMenuItems = JSON.parse(JSON.stringify(menuItems))
		return newMenuItems.filter(menuItem => {
			if (menuItem.path === paths.DISTRIBUTORS && !permissions.viewDistributors) {
				return false
			}
			if (menuItem.path === paths.PRODUCTS && !permissions.viewProducts) {
				return false
			}
			if (menuItem.path === paths.LOCATIONS && !permissions.viewLocations) {
				return false
			}
			if (menuItem.path === paths.REPORTS && !permissions.viewReports) {
				return false
			}
			if (menuItem.path === paths.USERS && !permissions.viewUsers) {
				return false
			}
			if (menuItem.path === paths.PURCHASE_ORDERS && (isPurchaseOrderEnabled === false || !permissions.viewPurchaseOrders)) {
				return false
			}
			if (menuItem.path === paths.FLEET && (isFleetEnabled === false || !permissions.viewFleet)) {
				return false
			}
			if (menuItem.path === paths.INVENTORY_PRODUCTS && (isInventoryEnabled === false || !permissions.viewInventory)) {
				return false
			}
			if (menuItem.path === paths.VENDORS && (isVendorEnabled === false || !permissions.viewVendors)) {
				return false
			}
			if (menuItem.path === paths.GENERATE_LABELS && (permissions.viewInventory || !permissions.createLabels)) {
				return false
			}
			if (menuItem.title === 'Access' && !permissions.viewUsers && !permissions.viewPermissionGroups) {
				return false
			}
			if (menuItem.children) {
				menuItem.children = menuItem.children.filter(subMenuItem => {
					if (subMenuItem.path === paths.USERS && !permissions.viewUsers) {
						return false
					}
					if (subMenuItem.path === paths.PERMISSIONS && !permissions.viewPermissionGroups) {
						return false
					}
					return true
				})
			}
			return true
		})
	}, [permissions, companyDetails])

	return (
		<div className={styles.container}>
			<Head>
				<title>Nuport</title>
				<link rel='icon' href='/favicon.ico' />
			</Head>
			<Layout className={styles.layout}>
				<Header className={styles.header}>
					{
						parentTitle && childTitle ?
							<div className={styles.leftContainer}>
								<img
									onClick={() => router.back()}
									className={styles.backButton} src='/img/back.svg' alt='Back Icon'
								/>
								<Breadcrumb>
									<Breadcrumb.Item
										onClick={parentLink ? () => router.push(parentLink) : null}
										style={{ color: 'black', cursor: 'pointer' }}
									>{parentTitle}
									</Breadcrumb.Item>
									<Breadcrumb.Item style={{ color: '#278EA5', fontWeight: 'bold' }}>
										{childTitle}
									</Breadcrumb.Item>
								</Breadcrumb>
							</div> :
							<div className={styles.leftContainer}>
								<img className={styles.actionButton} src='/img/menu.svg' alt='Menu Icon' onClick={() => setIsMenuVisible(true)} />
								{
									isNotEmpty(logoUrl) && role !== claimsRoles.ADMIN ?
										<img
											onClick={() => router.push('/')}
											className={styles.customLogo} src={logoUrl} alt='Nuport Logo'
										/> :
										<img
											onClick={() => router.push('/')}
											className={styles.logo} src='/img/logo.svg' alt='Nuport Logo'
										/>
								}
								{
									isSearchIconVisible() ?
										<div className={styles.searchButton}>
											<img
												onClick={onSearchIconClick}
												src='/img/search.svg' alt='Search'
											/>
										</div> : null
								}
								{
									isSearchApplied &&
									<Button
										style={{
											marginLeft: 20,
											border: '1px solid #D9E0E9',
											fontWeight: 'bold'
										}}
										type='primary'
										shape='round'
										size='large'
										onClick={clearSearchParams}
										ghost
									>
										Clear Filter
									</Button>
								}
							</div>
					}
					{
						role && role !== claimsRoles.ADMIN ?
							<div className={styles.centerContainer}>
								{
									Title ?
										<Title /> :
										<div className={styles.tabContainer}>
											{
												modifiedTabs.map(tab => {
													return (
														<div
															onClick={() => router.push(tab.path)}
															className={tab.path === currentTab ? styles.tabSelected : styles.tab}
															key={tab.path}
														>
															{tab.title}
														</div>
													)
												})
											}
										</div>
								}
							</div> : null
					}
					<div className={styles.rightContainer}>
						<Dropdown overlay={<ProfileMenu logout={logout} />} trigger={['click']}>
							<Avatar
								className={styles.actionButton}
								icon={<UserOutlined />}
							/>
						</Dropdown>
						{
							role === claimsRoles.USER || role === claimsRoles.OWNER ?
								<Dropdown overlay={<NotificationsTray />} trigger={['click']}>
									<Badge
										size='default'
										dot={unreadCount > 0}
									>
										<img
											className={styles.notificationIcon}
											src='/img/alert.svg'
										/>
									</Badge>
								</Dropdown> : null
						}
					</div>
				</Header>
				<Content
					className={styles.content}
					style={{ padding: fullPage ? '0' : '0 50px' }}
				>
					{children}
				</Content>
				<DeliveryPlanFilterModal
					visible={isDeliveryPlanFilterModalVisible}
					onCancel={() => setIsDeliveryPlanFilterModalVisible(false)}
				/>
				<SalesOrderFilterModal
					visible={isSalesOrderFilterModalVisible}
					onCancel={() => setIsSalesOrderFilterModalVisible(false)}
				/>
				<ProductFilterModal
					visible={isProductFilterModalVisible}
					onCancel={() => setIsProductFilterModalVisible(false)}
				/>
				<DistributorFilterModal
					visible={isDistributorFilterModalVisible}
					onCancel={() => setIsDistributorFilterModalVisible(false)}
				/>
				<InventoryFilterModal
					visible={isInventoryFilterModalVisible}
					onCancel={() => setIsInventoryFilterModalVisible(false)}
				/>
				<InventorySummaryFilterModal
					visible={isInventorySummaryFilterModalVisible}
					onCancel={() => setIsInventorySummaryFilterModalVisible(false)}
				/>
				<InventorySkuFilterModal
					visible={isInventorySkuFilterModalVisible}
					onCancel={() => setIsInventorySkuFilterModalVisible(false)}
				/>
				<InventoryOnHandStockFilterModal
					visible={isInventoryOnHandStockFilterModalVisible}
					onCancel={() => setIsInventoryOnHandStockFilterModalVisible(false)}
				/>
				<InventoryOrderMovementFilterModal
					visible={isInventoryOrderMovementFilterModalVisible}
					onCancel={() => setIsInventoryOrderMovementFilterModalVisible(false)}
				/>
				<InventoryScanAdherenceFilterModal
					visible={isInventoryScanAdherenceFilterModalVisible}
					onCancel={() => setIsInventoryScanAdherenceFilterModalVisible(false)}
				/>
				{
					isVehicleFilterModalVisible ?
						<VehicleFilterModal
							visible={isVehicleFilterModalVisible}
							onCancel={() => setIsVehicleFilterModalVisible(false)}
						/> : null
				}
				<UserFilterModal
					visible={isUserFilterModalVisible}
					onCancel={() => setIsUserFilterModalVisible(false)}
				/>
				<ReportFilterModal
					visible={isReportFilterModalVisible}
					onCancel={() => setIsReportFilterModalVisible(false)}
				/>
			</Layout>
			<Drawer
				placement='left'
				closable={false}
				onClose={() => setIsMenuVisible(false)}
				visible={isMenuVisible}
			>
				<div className={styles.menuContainer}>
					<div className={styles.menuHeader}>
						<img className={styles.menuLogo} src='/img/logo.svg' alt='Nuport Logo' />
						<img className={styles.actionButton}
							style={{ marginLeft: 'auto' }}
							src='/img/back.svg' alt='Back Icon' onClick={() => setIsMenuVisible(false)}
						/>
					</div>
					{
						modifiedMenuItems.map(item => {
							return (
								item.children ?
									<div className={styles.menuItem} key={item.title}>
										<div className={styles.menuItemContainer} onClick={() => {
											if (currentExpandedMenuItem === item.title) {
												setCurrentExpandedMenuItem(null)
											} else {
												setCurrentExpandedMenuItem(item.title)
											}
										}}
										>
											<img className={styles.menuItemIcon} src={item.icon} />
											<div className={styles.menuItemTitle}>{item.title}</div>
											{
												currentExpandedMenuItem === item.title ?
													<UpOutlined className={styles.chevron} /> :
													<DownOutlined className={styles.chevron} />
											}
										</div>
										{
											currentExpandedMenuItem === item.title &&
											item.children.map(child => {
												return (
													<Link
														className={styles.menuItem} key={child.title} href={child.path}
													>
														<a href={child.path}>
															<div className={styles.menuItemContainer} onClick={() => setIsMenuVisible(false)}>
																<div className={styles.menuDot} />
																<div className={styles.menuItemTitle}>{child.title}</div>
															</div>
														</a>
													</Link>
												)
											})
										}
									</div> :
									<Link
										className={styles.menuItem} key={item.title} href={item.path}
									>
										<a href={item.path}>
											<div className={styles.menuItemContainer} onClick={() => setIsMenuVisible(false)}>
												<img className={styles.menuItemIcon} src={item.icon} />
												<div className={styles.menuItemTitle}>{item.title}</div>
											</div>
										</a>
									</Link>
							)
						})
					}
					<div
						onClick={logout}
						className={styles.menuItemContainer} style={{ marginTop: 'auto' }}
					>
						<img className={styles.menuItemIcon} src='/img/logout.svg' />
						<div className={styles.menuItemTitle}>Logout</div>
					</div>
				</div>
			</Drawer>
		</div>
	)
}

export default withAuthUser({
	whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
	whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
	LoaderComponent: FullPageLoader
})(Page)

const ProfileMenu = ({ logout, ...props }) => {
	const { userProfile, role } = useSelector(state => state.authReducer)
	return (
		<Menu
			{...props}
		>
			{
				userProfile && role !== claimsRoles.ADMIN ?
					<Menu.Item
						key='info'
					>
						<Link
							href='/company'
						>
							<div className={styles.infoContainer}>
								{
									userProfile.company ?
										<div
											className={styles.companyName}
										>
											{userProfile.company.name}
										</div> : null
								}
								<div className={styles.name}>
									{userProfile.name}
								</div>
							</div>
						</Link>
					</Menu.Item> : null
			}
			<Menu.Item
				key='profile'
				icon={<UserOutlined />} style={{ padding: '10px 20px' }}
			>
				<Link href='/profile'>
					Profile
				</Link>
			</Menu.Item>
			<Menu.Item
				key='logout'
				onClick={logout}
				icon={<LogoutOutlined />} style={{ padding: '10px 20px' }}
			>
				Logout
			</Menu.Item>
		</Menu>
	)
}
