import { destroy, get, patch, post } from './index'
import moment from 'moment'

export const Inventory = {
	indexInventory: ({
		page = 0,
		searchTerm,
		locationId,
		updatedFrom,
		updatedTo,
		minQuantity,
		maxQuantity
	} = {}) =>
		get('/inventory', {
			params: {
				page,
				searchTerm,
				locationId,
				updatedFrom,
				updatedTo,
				minQuantity,
				maxQuantity
			}
		}),
	singleWarehouse: (id) => get(`/inventory/warehouses/${id}`),
	fetchSalesOrderInventory: (id) => get(`/inventory/sales-orders/${id}`),
	bulkUpdateInventory: ({
		inventory = []
	} = {}) =>
		post('/inventory', {
			inventory
		}),
	fetchInventoryProductCounts: ({
		page = 0,
		searchTerm,
		locationId,
		productId,
		updatedFrom,
		updatedTo,
		createdFrom,
		createdTo,
		serialNumber,
		palletId,
		packCode,
		sku,
		isOrdered,
		distinctFieldName,
		onlyOnHandStock,
		skus,
		state,
		chargeTypes,
		originIds,
		typeIds,
		dayShifts,
		remark,
		pageSize
	} = {}) =>
		get('/inventory/inventory-items/counts', {
			params: {
				page,
				searchTerm,
				locationId,
				productId,
				updatedFrom,
				updatedTo,
				state,
				distinctFieldName,
				createdFrom,
				createdTo,
				serialNumber,
				palletId,
				packCode,
				isOrdered,
				onlyOnHandStock,
				sku,
				skus,
				chargeTypes,
				originIds,
				typeIds,
				dayShifts,
				remark,
				pageSize
			}
		}),
	indexInventoryProducts: ({
		page = 0,
		searchTerm,
		locationId,
		productId,
		updatedFrom,
		updatedTo,
		createdFrom,
		createdTo,
		serialNumber,
		palletId,
		packCode,
		sku,
		isOrdered,
		distinctFieldName,
		onlyOnHandStock,
		skus,
		state,
		chargeTypes,
		originIds,
		typeIds,
		dayShifts,
		remark,
		pageSize
	} = {}) =>
		get('/inventory/inventory-items', {
			params: {
				page,
				searchTerm,
				locationId,
				productId,
				updatedFrom,
				updatedTo,
				state,
				distinctFieldName,
				createdFrom,
				createdTo,
				serialNumber,
				palletId,
				packCode,
				isOrdered,
				onlyOnHandStock,
				sku,
				skus,
				chargeTypes,
				originIds,
				typeIds,
				dayShifts,
				remark,
				pageSize
			}
		}),
	fetchInventoryProductSummaryItems: ({
		page = 0,
		searchTerm,
		state,
		pageSize,
		productId,
		originIds,
		typeIds
	} = {}) =>
		get('/inventory/inventory-items/summary', {
			params: {
				page,
				searchTerm,
				state,
				pageSize,
				productId,
				originIds,
				typeIds
			}
		}),
	indexInventoryOnHandStock: ({
		page = 0,
		searchTerm,
		updatedFrom,
		updatedTo,
		createdFrom,
		createdTo,
		serialNumber,
		palletId,
		packCode,
		sku,
		skus,
		productIds,
		shifts,
		batchNumbers,
		machineCodes,
		shelfLife,
		facilities,
		sortInTransitQuantity,
		sortOnHandStockQuantity
	} = {}) =>
		get('/inventory/on-hand-stock', {
			params: {
				page,
				searchTerm,
				updatedFrom,
				updatedTo,
				createdFrom,
				createdTo,
				serialNumber,
				palletId,
				packCode,
				sku,
				skus,
				productIds,
				shifts,
				batchNumbers,
				machineCodes,
				shelfLife,
				facilities,
				sortInTransitQuantity,
				sortOnHandStockQuantity
			}
		}),
	indexOnHandStockFilterOptions: ({
		page = 0,
		searchTerm,
		distinctFieldName,
		shelfLife
	} = {}) =>
		get('/inventory/on-hand-stock/filter-options', {
			params: {
				page,
				searchTerm,
				distinctFieldName,
				shelfLife
			}
		}),
	indexOrderMovementFilterOptions: ({
		page = 0,
		searchTerm,
		distinctFieldName,
		shelfLife,
		sku,
		destinationLocation,
		sourceLocation,
		orderNumber
	} = {}) =>
		get('/inventory/order-movement/filter-options', {
			params: {
				page,
				searchTerm,
				distinctFieldName,
				shelfLife,
				sourceLocation,
				destinationLocation,
				sku,
				orderNumber
			}
		}),
	indexInventoryOrderMovement: ({
		page = 0,
		searchTerm,
		createdFrom,
		createdTo,
		shifts,
		shelfLife,
		dropOffLocationIds,
		pickUpLocationIds,
		orderNumbers,
		isOrderDelivered,
		orderTypes
	} = {}) =>
		get('/inventory/order-movement', {
			params: {
				page,
				searchTerm,
				createdFrom,
				createdTo,
				shifts,
				shelfLife,
				dropOffLocationIds,
				pickUpLocationIds,
				orderNumbers,
				isOrderDelivered,
				orderTypes
			}
		}),
	indexInventoryScanAdherence: ({
		orderDateStart,
		orderDateEnd,
		orderDates,
		orderTypes,
		pickUpLocationIds,
		dropOffLocationIds,
		orderIds
	} = {}) =>
		get('/inventory/scan-adherence', {
			params: {
				orderDateStart,
				orderDateEnd,
				orderDates,
				orderTypes,
				pickUpLocationIds,
				dropOffLocationIds,
				orderIds
			}
		}),
	downloadOnHandStockCsv: async (params) => {
		const response = await get('/inventory/on-hand-stock/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `on_hand_stock_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadOnHandStockXlsx: async (params) => {
		const response = await get('/inventory/on-hand-stock/xlsx', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `on_hand_stock_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.click()
		link.remove()
	},
	downloadOrderMovementCsv: async (params) => {
		const response = await get('/inventory/order-movement/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `order_movement_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadOrderMovementXlsx: async (params) => {
		const response = await get('/inventory/order-movement/xlsx', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `order_movement_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.click()
		link.remove()
	},
	downloadStockMonitoringCsv: async (params) => {
		const response = await get('/inventory/stock-monitoring/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `stock_monitoring_by_${params.bySku ?
			'sku' : 'location'}_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadStockMonitoringXlsx: async (params) => {
		const response = await get('/inventory/stock-monitoring/xlsx', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `stock_monitoring_by_${params.bySku ?
			'sku' : 'location'}_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.click()
		link.remove()
	},
	downloadScanAdherenceXlsx: async (params) => {
		const response = await get('/inventory/scan-adherence/xlsx', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `scan_adherence_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.click()
		link.remove()
	},
	downloadInventoryItemsCsv: async (payload) => {
		const response = await post('/inventory/inventory-items/csv', payload, {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `inventory_${payload.state?.toLowerCase()}_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	fetchReferenceNumber: (workCenter) => get('/inventory/inventory-items/reference-number', {
		params: {
			machineCode: workCenter
		}
	}),
	takeReferenceNumber: ({
		machineCode,
		count
	}) => post('/inventory/inventory-items/reference-number', {
		machineCode,
		count
	}),
	extendShelfLife: ({
		remark,
		shelfLife,
		inventoryProductItemIds
	}) => patch('/inventory/inventory-items/shelf-life', {
		remark,
		shelfLife,
		inventoryProductItemIds
	}),
	blockInventoryProducts: ({
		blocked,
		serialNumbers,
		remark,
		batchNumber,
		productId
	}) => patch('/inventory/inventory-items/blocked', {
		blocked,
		serialNumbers,
		remark,
		batchNumber,
		productId
	}),
	createInventoryProduct: (data) =>
		post('/inventory/inventory-items', data),
	bulkCreateInventoryProducts: (data) =>
		post('/inventory/bulk-inventory-items', data),
	fetchOrderedInventoryProductItems: (id, { page = 0, searchTerm } = {}) =>
		get(`/inventory/products/${id}/inventory-items`, {
			params: {
				page,
				searchTerm
			}
		}),
	fetchInventoryStockMonitoring: ({
		page = 0,
		bySku,
		byLocation,
		skus,
		facilities,
		shelfLifeRangeKey,
		shelfLives
	} = {}) =>
		get('/inventory/stock-monitoring', {
			params: {
				page,
				byLocation,
				bySku,
				skus,
				facilities,
				shelfLives,
				shelfLifeRangeKey
			}
		}),
	deleteInventoryProductItem: serialNumber =>
		destroy(`/inventory/inventory-items/${serialNumber}`),
	bulkDeleteInventoryProductItems: ids =>
		patch('/inventory/inventory-items/bulk-delete', { ids }),
	updateInventoryProductItem: (serialNumber, { status }) =>
		patch(`/inventory/inventory-items/${serialNumber}`, { status }),
	fetchProductTotals: ({ searchTerm, locationIds } = {}) => get('/inventory/products', {
		params: {
			searchTerm,
			locationIds
		}
	}),
	fetchProductLocations: (productId) => get(`/inventory/products/${productId}/locations`),
	fetchProductsAtLocation: (locationId) => get(`/inventory/products/locations/${locationId}`),
	palletizeInventoryItems: ({ serialNumbers, batchNumber, state, productId }) => patch('/inventory/inventory-items/pallet', { serialNumbers, batchNumber, state, productId }),
	updateInventoryItemsToDistribution: ({ serialNumbers, batchNumber, state, productId }) => patch('/inventory/inventory-items/distribution-receive', { serialNumbers, batchNumber, state, productId }),
	returnInventoryProductItems: (locationId, serialNumbers) => patch('/inventory/inventory-items/return', { locationId, serialNumbers }),
	restockInventoryProductItems: (serialNumbers) => patch('/inventory/inventory-items/restock', { serialNumbers }),
	preStockInventoryProductItems: ({ serialNumbers, batchNumber, state, productId }) => patch('/inventory/inventory-items/pre-stock', { serialNumbers, batchNumber, state, productId }),
	rechargeInventoryProductItems: (serialNumbers) => patch('/inventory/inventory-items/recharge', { serialNumbers }),
	damageInventoryProductItems: (serialNumbers) => patch('/inventory/inventory-items/damage', { serialNumbers }),
	convertInventoryProductItems: ({ productId, serialNumbers, chargeType } = {}) => patch('/inventory/inventory-items/convert', { productId, serialNumbers, chargeType })
}
