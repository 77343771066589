import { useEffect, useState } from 'react'
import { Button, Checkbox, Divider, Modal, Select, notification } from 'antd'
import SearchInput from '../search-input'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './InventorySkuFilterModal.module.css'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
require('./inventory-sku-filter-modal.less')

const InventorySkuFilterModal = ({ visible, onCancel }) => {
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const [isLoadingWarehouses, setIsLoadingWarehouses] = useState(false)
	const [isAllWarehousesChecked, setIsAllWarehousesChecked] = useState(false)
	const [warehouses, setWarehouses] = useState([])
	const [selectedWarehouses, setSelectedWarehouses] = useState([])
	const [warehouseSearchValue, setWarehouseSearchValue] = useState('')

	useEffect(() => {
		if (visible) {
			fetchWarehouses()
		}
	}, [visible])

	const fetchWarehouses = async () => {
		try {
			setIsLoadingWarehouses(true)
			const response = await Locations.index({ page: -1, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
			if (response.data && response.data.results) {
				setWarehouses(response.data.results.map((item) => ({ value: item.id, label: item.label || item.address })))
			}
		} catch (e) {
			notification.error({
				message: 'Unable To Fetch Warehouse',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingWarehouses(false)
		}
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (selectedWarehouses?.length) {
			searchParams.locationIds = selectedWarehouses
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setIsAllWarehousesChecked(false)
		setSelectedWarehouses([])
		clearSearchParams()
	}

	const isResetButtonDisabled = () => {
		return !searchTerm && !selectedWarehouses.length
	}

	const isApplyButtonEnabled = () => {
		return searchTerm || selectedWarehouses?.length
	}

	const onWarehouseCheck = (value) => {
		if (selectedWarehouses.includes(value)) {
			setSelectedWarehouses(selectedWarehouses.filter(item => item !== value))
		} else {
			setSelectedWarehouses([...selectedWarehouses, value])
		}
	}

	const onSelectAllWarehouses = (e) => {
		if (e.target.checked) {
			setIsAllWarehousesChecked(true)
			const warehouseOptions = warehouseSearchValue ?
				warehouses.filter(item => item.label?.toLowerCase()?.includes(warehouseSearchValue.toLowerCase()))
				: warehouses
			setSelectedWarehouses(warehouseOptions.map(item => item.value))
		} else {
			setIsAllWarehousesChecked(false)
			setSelectedWarehouses([])
		}
	}

	const renderWarehouseOptions = () => {
		const warehouseOptions = warehouseSearchValue ?
			warehouses.filter(item => item.label?.toLowerCase()?.includes(warehouseSearchValue.toLowerCase()))
			: warehouses
		return (
			<div className={styles.optionsContainer}>
				<Checkbox
					onChange={onSelectAllWarehouses}
					checked={isAllWarehousesChecked}
					value={isAllWarehousesChecked}
				>
					Select All
				</Checkbox>
				<Divider style={{ margin: '5px 0px' }} />
				<div className={styles.options}>
					{
						warehouseOptions.map((option) => {
							return (
								<div style={{ marginTop: 5 }} key={option.value}>
									<Checkbox
										onChange={() => onWarehouseCheck(option.value)}
										checked={selectedWarehouses.includes(option.value)}
										value={option.value}
									>
										{option.label}
									</Checkbox>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						disabled={isResetButtonDisabled()}
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isApplyButtonEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Product Code or Name'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<div style={{ marginTop: 20 }}>
						<Select
							loading={isLoadingWarehouses}
							style={{ width: '100%' }}
							className='warehouse-select'
							showSearch
							onSearch={setWarehouseSearchValue}
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
							placeholder='Select Warehouses'
							options={warehouses}
							dropdownRender={renderWarehouseOptions}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default InventorySkuFilterModal
