import { useEffect, useMemo, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import SearchInput from '../search-input'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './InventoryFilterModal.module.css'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import CustomSearchableSelect from '../custom-searchable-select'
import Input from '../input'
import moment from 'moment'
import { convertEnumToString } from '../../utils'
import { useSelector } from 'react-redux'
import CustomSelect from '../custom-select'
import { chargeTypeOptions, dayShiftOptions } from '../../utils/constants'
import { Products } from '../../services/api/products'

const InventoryFilterModal = ({ visible, onCancel }) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams, isSearchApplied } = useSearchParams()
	const [location, setLocation] = useState()
	const [locations, setLocations] = useState()
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [isLoadingOrigins, setIsLoadingOrigins] = useState(false)
	const [isLoadingTypes, setIsLoadingTypes] = useState(false)
	const [origins, setOrigins] = useState([])
	const [types, setTypes] = useState([])
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [chargeTypes, setChargeTypes] = useState([])
	const [selectedTypes, setSelectedTypes] = useState([])
	const [selectedOrigins, setSelectedOrigins] = useState([])
	const [products, setProducts] = useState([])
	const [selectedProduct, setSelectedProduct] = useState(null)
	const [selectedShifts, setSelectedShifts] = useState([])
	const isPalletNumberEnabled = useMemo(() => companyDetails?.isPalletNumberEnabled, [companyDetails])

	useEffect(() => {
		if (visible) {
			fetchProducts()
			fetchWarehouses()
			fetchOrigins()
		}
	}, [visible])

	useEffect(() => {
		if (selectedOrigins.length) {
			fetchTypes()
		}
	}, [selectedOrigins])

	useEffect(() => {
		if (!isSearchApplied) {
			resetFilter()
		}
	}, [isSearchApplied])

	const fetchProducts = async () => {
		try {
			const { data } = await Products.index({ page: -1 })
			if (data.results) {
				setProducts(data.results)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Products!',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const searchProducts = async value => {
		return Products.index({ searchTerm: value })
	}

	const fetchWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const fetchOrigins = async () => {
		setIsLoadingOrigins(true)
		const response = await Products.fetchCategories({ page: -1 })
		if (response.data) {
			setOrigins(response.data.results?.map((item) => ({ label: item.name, value: item.id })))
		}
		setIsLoadingOrigins(false)
	}

	const fetchTypes = async () => {
		setIsLoadingTypes(true)
		const response = await Products.fetchCategories({ page: -1, parentIds: selectedOrigins })
		if (response.data) {
			setTypes(response.data.results?.map((item) => ({ label: item.name, value: item.id })))
		}
		setIsLoadingTypes(false)
	}

	const searchLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (location) {
			searchParams.locationId = location.value
		}
		if (fromDate) {
			searchParams.updatedFrom = fromDate
		}
		if (toDate) {
			searchParams.updatedTo = toDate
		}
		if (chargeTypes?.length) {
			searchParams.chargeTypes = chargeTypes
		}
		if (selectedOrigins?.length) {
			searchParams.originIds = selectedOrigins
		}
		if (selectedTypes?.length) {
			searchParams.typeIds = selectedTypes
		}
		if (selectedProduct) {
			searchParams.productId = selectedProduct.value
		}
		if (selectedShifts?.length) {
			searchParams.dayShifts = selectedShifts
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setLocation(null)
		setFromDate(null)
		setToDate(null)
		setChargeTypes([])
		setSelectedOrigins([])
		setSelectedTypes([])
		clearSearchParams()
		setSelectedProduct(null)
		setSelectedShifts([])
	}

	const isResetButtonDisabled = () => {
		return !searchTerm && !location && !fromDate && !toDate && chargeTypes.length === 0 && selectedOrigins.length === 0 && selectedTypes.length === 0 && !selectedProduct && selectedShifts.length === 0
	}

	const isApplyButtonEnabled = () => {
		return searchTerm || location || fromDate && toDate || chargeTypes.length || selectedOrigins.length || selectedTypes.length || selectedProduct || selectedShifts.length > 0
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						disabled={isResetButtonDisabled()}
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isApplyButtonEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder={ isPalletNumberEnabled ? 'Search Barcode Number, Batch Number, or Pallet Number' : 'Search Barcode Number, or Batch Number'}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Product'
						searchTask={searchProducts}
						defaultOptions={products}
						isLoading={isLoadingLocations}
						customLabel={(data) => `${data.name} - ${data.code}`}
						valueIndex='id'
						placeholder='Select Product'
						onChange={product => setSelectedProduct(product)}
						onClear={() => setSelectedProduct(null)}
						style={{ width: 494 }}
						value={selectedProduct}
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Warehouse'
						searchTask={searchLocations}
						defaultOptions={locations}
						isLoading={isLoadingLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						placeholder='Select Warehouse'
						onChange={location => setLocation(location)}
						onClear={() => setLocation(null)}
						style={{ width: 494 }}
						value={location}
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Updated At</h3>
					<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
						<Input
							title='From'
							style={{ flex: 1 }}
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<div className={styles.dash} />
						<Input
							style={{ flex: 1 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</div>
				</div>
				<div className={styles.section}>
					<CustomSelect
						style={{ width: '100%' }}
						title='Origin'
						loading={isLoadingOrigins}
						options={origins}
						value={selectedOrigins}
						onChange={setSelectedOrigins}
						allowClear={true}
						mode='multiple'
					/>
				</div>
				<div className={styles.section}>
					<CustomSelect
						style={{ width: '100%' }}
						title='Types'
						loading={isLoadingTypes}
						options={types}
						value={selectedTypes}
						onChange={setSelectedTypes}
						allowClear={true}
						mode='multiple'
					/>
				</div>
				<div className={styles.section}>
					<CustomSelect
						style={{ width: '100%' }}
						title='Charge Type'
						options={chargeTypeOptions}
						value={chargeTypes}
						onChange={setChargeTypes}
						allowClear={true}
						mode='multiple'
					/>
				</div>
				<div className={styles.section}>
					<CustomSelect
						style={{ width: '100%' }}
						title='Production Shift'
						options={dayShiftOptions}
						value={selectedShifts}
						onChange={setSelectedShifts}
						allowClear={true}
						mode='multiple'
					/>
				</div>
			</div>
		</Modal>
	)
}

export default InventoryFilterModal
