import { HYDRATE } from 'next-redux-wrapper'
import { SET_INVENTORY_ACTION_STATES, SET_SEARCH_PARAMS } from './action-types'
import { InventoryProductState, InventoryProductView } from '../../utils/constants'

const initialState = {
	searchParams: {},
	inventory: {
		selectedSerialNumbers: [],
		selectedSubTab: InventoryProductState.GENERATED,
		viewType: InventoryProductView.SUMMARY,
		selectedItemIds: [],
		selectedItems: [],
		summaryItem: null
	}
}

const commonReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case SET_SEARCH_PARAMS: {
			return { ...state, searchParams: { ...payload } }
		}
		case SET_INVENTORY_ACTION_STATES: {
			return { ...state, inventory: { ...state.inventory, ...payload } }
		}
		default:
			return state
	}
}

export default commonReducer
