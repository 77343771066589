import { useEffect, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import SearchInput from '../search-input'
import useSearchParams from '../../hooks/useSearchParams'
import CustomCascader from '../../components/custom-cascader'
import styles from './ProductFilterModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { buildCategoryOptions } from '../../utils/products'
import { fetchProductCategories } from '../../store/products/actions'
import CustomSearchableSelect from '../custom-searchable-select'
import { Products } from '../../services/api/products'

const ProductFilterModal = ({ visible, onCancel }) => {
	const dispatch = useDispatch()
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams, isSearchApplied } = useSearchParams()
	const { categories } = useSelector(state => state.productsReducer)
	const [categoryOptions, setCategoryOptions] = useState()
	const [selectedCategories, setSelectedCategories] = useState([])
	const [selectedBrands, setSelectedBrands] = useState([])
	const [brands, setBrands] = useState([])

	useEffect(() => {
		if (visible) {
			dispatch(fetchProductCategories({ page: -1 }))
			fetchBrands()
		}
	}, [visible])

	useEffect(() => {
		createCategoryOptions()
	}, [categories])

	useEffect(() => {
		if (!isSearchApplied) {
			resetFilter()
		}
	}, [isSearchApplied])

	const fetchBrands = async () => {
		try {
			const { data } = await Products.fetchBrands({ page: 0 })
			if (data.results) {
				setBrands(data.results)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Brands!',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (selectedCategories?.length) {
			searchParams.categoryIds = selectedCategories
		} else {
			searchParams.categoryIds = undefined
		}
		if (selectedBrands?.length) {
			searchParams.brandIds = selectedBrands.map(l => l.value)
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setSelectedCategories([])
		setSelectedBrands([])
		clearSearchParams()
	}

	const createCategoryOptions = () => {
		const options = buildCategoryOptions(categories)
		setCategoryOptions(options)
	}

	const searchBrands = async value => {
		return Products.fetchBrands({ searchTerm: value, page: 0 })
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={false}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Product Name or SKU'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Brands</h3>
					<CustomSearchableSelect
						searchTask={searchBrands}
						defaultOptions={brands}
						isLoading={false}
						valueIndex='id'
						labelIndex='name'
						placeholder='Select Brand Names'
						onChange={options => setSelectedBrands(options)}
						onClear={() => setSelectedBrands([])}
						style={{ width: '100%' }}
						value={selectedBrands}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Categories</h3>
					<CustomCascader
						allowClear={false}
						placeholder='Select a category and/or sub-categories...'
						changeOnSelect={true}
						options={categoryOptions}
						value={selectedCategories}
						onChange={setSelectedCategories}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ProductFilterModal
