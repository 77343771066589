import { get, patch, post } from './index'

export const Locations = {
	index: ({
		searchTerm,
		page = 0,
		description,
		type,
		types,
		inventory,
		usages,
		sortOptions
	} = {}) =>
		get('/locations', {
			params: {
				searchTerm,
				page,
				description,
				type,
				types,
				inventory,
				usages,
				sortOptions
			}
		}),
	create: (params) =>
		post('/locations', params),
	single: (id) =>
		get(`/locations/${id}`),
	update: (id, params) => patch(`/locations/${id}`, params)
}
