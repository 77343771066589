export const paths = {
	HOME: '/',
	DASHBOARD: '/',
	DISTRIBUTORS: '/distributors',
	PRODUCTS: '/products',
	INVENTORY: '/inventory',
	INVENTORY_PRODUCTS: '/inventory-products',
	REPORTS: '/reports',
	USERS: '/users',
	PERMISSIONS: '/permissions',
	COMPANIES: '/companies',
	SALES_ORDERS: '/sales-orders',
	DELIVERY_PLANS: '/delivery-plans',
	FLEET: '/fleet',
	VENDORS: '/vendors',
	PURCHASE_ORDERS: '/purchase-orders',
	LOCATIONS: '/locations',
	SETTINGS: '/settings',
	WAREHOUSES: '/warehouses',
	GENERATE_LABELS: '/generate-labels'
}

export const roles = {
	OWNER: 'OWNER',
	USER: 'USER',
	DRIVER: 'DRIVER',
	HELPER: 'HELPER',
	DISTRIBUTOR: 'DISTRIBUTOR',
	SALES_MANAGER: 'SALES_MANAGER'
}

export const claimsRoles = {
	ADMIN: 'admin',
	OWNER: 'owner',
	USER: 'user',
	DRIVER: 'driver',
	HELPER: 'helper',
	SALES_MANAGER: 'sales_manager'
}

export const locationTypes = {
	WAREHOUSE: 'WAREHOUSE',
	FACTORY: 'FACTORY',
	GENERAL: 'GENERAL',
	BILLING: 'BILLING'
}

export const warehouseDescriptions = {
	CENTRAL_WAREHOUSE: 'central_warehouse',
	FINISHED_GOODS_WAREHOUSE: 'finished_goods_warehouse',
	RETURN_WAREHOUSE: 'return_warehouse',
	DELIVERY_PIPELINE: 'delivery_pipeline',
	DISTRIBUTION: 'distribution'
}

export const intervalTypes = {
	DAILY: 'daily',
	WEEKLY: 'weekly',
	MONTHLY: 'monthly',
	CUSTOM: 'custom'
}

export const ChargeType = {
	WET: 'C',
	DRY: 'D'
}

export const chargeTypeOptions = [
	{
		label: 'Wet Charge',
		value: ChargeType.WET
	},
	{
		label: 'Dry',
		value: ChargeType.DRY
	}
]

export const DayShift = {
	MORNING: 'MORNING',
	EVENING: 'EVENING',
	NIGHT: 'NIGHT'
}

export const dayShiftOptions = [
	{
		value: DayShift.MORNING,
		label: 'Morning'
	},
	{
		value: DayShift.EVENING,
		label: 'Evening'
	},
	{
		value: DayShift.NIGHT,
		label: 'Night'
	}
]

export const InventoryProductState = {
	BLOCKED: 'BLOCKED',
	PRE_STOCK: 'PRE_STOCK',
	DELIVERED: 'DELIVERED',
	RETURNED: 'RETURNED',
	DAMAGED: 'DAMAGED',
	IN_STOCK: 'IN_STOCK',
	GENERATED: 'GENERATED',
	UNDER_DISTRIBUTION: 'UNDER_DISTRIBUTION'
}

export const InventoryProductView = {
	SUMMARY: 'SUMMARY',
	DETAILS: 'DETAILS'
}

export const InventoryTab = {
	INVENTORY: 'INVENTORY',
	SKU: 'SKU',
	ON_HAND_STOCK: 'ON_HAND_STOCK',
	ORDER_MOVEMENT: 'ORDER_MOVEMENT',
	STOCK_MONITORING: 'STOCK_MONITORING',
	SCAN_ADHERENCE: 'SCAN_ADHERENCE',
	FEED: 'FEED'
}

export const InventoryRemark = {
	UNDER_DISTRIBUTION: 'UNDER_DISTRIBUTION',
	RESTOCKED: 'RESTOCKED',
	CONVERTED: 'CONVERTED'
}

export const SalesOrderType = {
	STO: 'STO',
	SO: 'SO',
	RO: 'RO'
}

export const orderTypeOptions = [
	{
		value: SalesOrderType.SO,
		label: 'Sales Order'
	},
	{
		value: SalesOrderType.RO,
		label: 'Returned Order'
	},
	{
		value: SalesOrderType.STO,
		label: 'Stock Transfer Order'
	}
]

export const ORDER_REQUESTED_STATUS = 'REQUESTED'
export const ORDER_FLAGGED_STATUS = 'FLAGGED'
export const ORDER_APPROVED_STATUS = 'APPROVED'
export const ORDER_PROCESSING_STATUS = 'PROCESSING'
export const ORDER_COMPLETED_STATUS = 'COMPLETED'
export const ORDER_CANCELLED_STATUS = 'CANCELLED'

export const orderStatusOptions = [
	{
		label: 'Created',
		value: ORDER_APPROVED_STATUS
	},
	{
		label: 'In-Transit',
		value: ORDER_PROCESSING_STATUS
	},
	{
		label: 'Delivered',
		value: ORDER_COMPLETED_STATUS
	},
	{
		label: 'Cancelled',
		value: ORDER_CANCELLED_STATUS
	}
]
