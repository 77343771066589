import { useEffect, useMemo, useState } from 'react'
import { Button, Divider, Modal, Space, notification } from 'antd'
import SearchInput from '../search-input'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './inventory-on-hand-stock-filter.module.css'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import CustomSearchableSelect from '../custom-searchable-select'
import Input from '../input'
import moment from 'moment'
import { convertEnumToString, isNotEmpty } from '../../utils'
import { useSelector } from 'react-redux'
import { Products } from '../../services/api/products'
import CustomSelect from '../custom-select'
import { Inventory } from '../../services/api/inventory'
import { dayShiftOptions } from '../../utils/constants'

const InventoryOnHandStockFilterModal = ({ visible, onCancel }) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const [selectedLocations, setSelectedLocations] = useState([])
	const [locations, setLocations] = useState()
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [selectedProducts, setSelectedProducts] = useState([])
	const [products, setProducts] = useState([])
	const [selectedBatchNumbers, setSelectedBatchNumbers] = useState([])
	const [batchNumbers, setBatchNumbers] = useState([])
	const [selectedDayShifts, setSelectedDayShifts] = useState([])
	const [isLoadingProducts, setIsLoadingProducts] = useState(false)
	const [isLoadingBatchNumbers, setIsLoadingBatchNumbers] = useState(false)
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [shelfLife, setShelfLife] = useState('')
	const isStockMonitoringEnabled = useMemo(() => companyDetails?.isStockMonitoringEnabled, [companyDetails])

	useEffect(() => {
		if (visible) {
			fetchInitialWarehouses()
			fetchInitialProducts()
			fetchInventoryBatchNumbers()
		}
	}, [visible])

	const fetchInitialWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const searchLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const fetchInitialProducts = async () => {
		try {
			setIsLoadingProducts(true)
			const response = await Products.index({ page: 0 })
			if (response.data) {
				setProducts(response.data.results)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Products',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingProducts(false)
		}
	}

	const searchProducts = async value => {
		return Products.index({ searchTerm: value })
	}

	const fetchInventoryBatchNumbers = async () => {
		try {
			setIsLoadingBatchNumbers(true)
			const response = await Inventory.indexInventoryProducts({ page: 0, distinctFieldName: 'batchNumber', onlyOnHandStock: true })
			if (response.data) {
				setBatchNumbers(response.data.results)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Batch Numbers',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingBatchNumbers(false)
		}
	}

	const searchBatchNumbers = async value => {
		return Inventory.indexInventoryProducts({ searchTerm: value, distinctFieldName: 'batchNumber', onlyOnHandStock: true })
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (selectedLocations?.length) {
			searchParams.facilities = selectedLocations.map(l => l.value)
		}
		if (selectedProducts?.length) {
			searchParams.productIds = selectedProducts.map(p => p.value)
		}
		if (selectedBatchNumbers?.length) {
			searchParams.batchNumbers = selectedBatchNumbers.map(b => b.value)
		}
		if (selectedDayShifts?.length) {
			searchParams.shifts = selectedDayShifts
		}
		if (fromDate) {
			searchParams.createdFrom = fromDate
		}
		if (toDate) {
			searchParams.createdTo = toDate
		}
		if (isNotEmpty(shelfLife)) {
			searchParams.shelfLife = +shelfLife
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setSelectedLocations([])
		setFromDate(null)
		setToDate(null)
		setShelfLife('')
		setSelectedDayShifts([])
		setSelectedBatchNumbers([])
		setSelectedLocations([])
		setSelectedProducts([])
		clearSearchParams()
	}

	const isResetButtonDisabled = () => {
		return !searchTerm && !selectedLocations?.length && !selectedProducts?.length && !selectedDayShifts?.length && !fromDate && !toDate && !selectedBatchNumbers?.length && !shelfLife
	}

	const isApplyButtonEnabled = () => {
		return searchTerm || selectedLocations?.length || selectedProducts?.length || selectedDayShifts?.length || selectedBatchNumbers?.length || shelfLife || fromDate && toDate
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						disabled={isResetButtonDisabled()}
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isApplyButtonEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Product Name'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Current Location'
						searchTask={searchLocations}
						defaultOptions={locations}
						isLoading={isLoadingLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						onChange={locations => setSelectedLocations(locations)}
						onClear={() => setSelectedLocations([])}
						style={{ width: '100%' }}
						value={selectedLocations}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Product Code'
						searchTask={searchProducts}
						defaultOptions={products}
						isLoading={isLoadingProducts}
						customLabel={(data) => data.code}
						valueIndex='id'
						descriptionIndex='name'
						onChange={products => setSelectedProducts(products)}
						onClear={() => setSelectedProducts([])}
						style={{ width: '100%' }}
						value={selectedProducts}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Production Date</h3>
					<Space>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
				<div className={styles.section}>
					<CustomSelect
						title='Production Shift'
						options={dayShiftOptions}
						onChange={setSelectedDayShifts}
						onClear={() => setSelectedDayShifts([])}
						style={{ width: '100%' }}
						value={selectedDayShifts}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Batch Number'
						searchTask={searchBatchNumbers}
						defaultOptions={batchNumbers}
						isLoading={isLoadingBatchNumbers}
						customLabel={(data) => data.batchNumber}
						valueIndex='batchNumber'
						onChange={setSelectedBatchNumbers}
						onClear={() => setSelectedBatchNumbers([])}
						style={{ width: '100%' }}
						value={selectedBatchNumbers}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				{
					isStockMonitoringEnabled &&
						<div className={styles.section}>
							<Input
								title='Remaining Shelf Life in Days'
								type='text'
								style={{ width: '100%' }}
								value={shelfLife}
								onChange={(e) => {
									const value = e.target.value
									if (!isNaN(+value)) {
										setShelfLife(value)
									} else {
										setShelfLife('')
									}
								}}
							/>
						</div>
				}
			</div>
		</Modal>
	)
}

export default InventoryOnHandStockFilterModal
