import UploadCsvModal from '../upload-csv-modal'
import { Upload } from '../../services/api/upload'
import { useEffect, useState } from 'react'
import { Products } from '../../services/api/products'
import { notification } from 'antd'
import { locationTypes } from '../../utils/constants'
import { Locations } from '../../services/api/locations'

const UploadOrdersModal = ({ visible, onCancel, onComplete }) => {
	const [loading, setLoading] = useState(true)
	const [fields, setFields] = useState([
		{ label: 'Order ID', key: 'internalId', type: 'string' },
		{ label: 'Order Date', key: 'orderDate', type: 'string' },
		{ label: 'Order Type', key: 'orderType', type: 'enum',
			config: {
				options: [
					{ label: 'SO', value: 'SO' },
					{ label: 'RO', value: 'RO' },
					{ label: 'STO', value: 'STO' }
				]
			}
		},
		{ label: 'Warehouse', key: 'warehouse', type: 'enum', config: { options: [] } },
		{ label: 'Dealer Name', key: 'dealerName', type: 'string' }
	])

	useEffect(() => {
		if (visible) {
			fetchInitialData()
		}
	}, [visible])

	const fetchInitialData = async () => {
		try {
			const [products, locations] = await Promise.all([
				Products.index({ page: -1 }),
				Locations.index({ page: -1, types: [locationTypes.FACTORY, locationTypes.WAREHOUSE] })
			])
			const options = locations?.data?.results?.map((location) => ({
				label: location.label,
				value: location.id
			}))
			const newFields = fields.map((field) => {
				if (field.key === 'warehouse') {
					return { ...field, config: { options } }
				}
				return field
			})
			const productFields = products?.data?.results?.map((product) => ({
				label: product.name,
				key: product.id,
				type: 'number'
			}))
			setFields([...newFields, ...productFields])
		} catch (e) {
			notification.error({
				message: 'Unable to fetch products or locations.',
				description: e.message
			})
		} finally {
			setLoading(false)
		}
	}

	return (
		<UploadCsvModal
			title='Upload Orders'
			type='ORDERS'
			fields={fields}
			loading={loading}
			firstStepPrompt='Download an example CSV file. Do not modify the first row of the CSV. This CSV will inform you on how to structure your data.'
			secondStepPrompt='Edit the CSV file by adding new rows. Rows will be uploaded as new orders into the platform.'
			visible={visible}
			onCancel={onCancel}
			onComplete={onComplete}
			downloadTask={Upload.downloadOrdersCsv}
			uploadTask={Upload.uploadOrdersCsv}
		/>
	)
}

export default UploadOrdersModal
