import { Select, Input, Divider, Button, notification } from 'antd'
import styles from './CustomSelect.module.css'
// We use Less styles to override Ant stylings.
require('./CustomSelect.less')
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { convertStringToEnum } from '../../utils'
import InlineTextEditor from '../inline-text-editor'

const { Option, OptGroup } = Select

const CustomSelect = ({
	options = [],
	title,
	style,
	defaultValue,
	placeholder,
	value,
	onChange,
	addItem,
	onAddItem,
	onUpdateItem,
	loading,
	small,
	disabled,
	labelInValue,
	allowClear,
	disabledOptions = { }, // Map of options to disable,
	optGroupLabel,
	unclipOptions = false,
	popupContainer,
	mode,
	showSearch = false,
	onSearch = () => { },
	filterOption
}) => {
	const [addItemInputValue, setAddItemInputValue] = useState()
	const [currentOptions, setCurrentOptions] = useState(options)
	const [isAddingItem, setIsAddingItem] = useState(false)
	const [editItem, setEditItem] = useState(false)
	const inputRefs = useRef([])

	useEffect(() => {
		setCurrentOptions(options)
	}, [options])

	const onAddItemClick = async () => {
		if (addItemInputValue) {
			if (onAddItem) {
				setIsAddingItem(true)
				const addedItem = await onAddItem(addItemInputValue)
				if (addedItem && addedItem['value'] && addedItem['label']) {
					const newOptions = [
						{
							value: addedItem['value'],
							label: addedItem['label']
						},
						...currentOptions
					]
					setCurrentOptions(newOptions)
					setAddItemInputValue(null)
				}
				setIsAddingItem(false)
			} else {
				const newItemValue = convertStringToEnum(addItemInputValue)
				if (currentOptions.findIndex(option => option.value === newItemValue) === -1) {
					const newOptions = [...currentOptions, {
						value: newItemValue,
						label: addItemInputValue
					}]
					setCurrentOptions(newOptions)
					setAddItemInputValue(null)
				}
			}
		}
	}

	const saveEditedItem = async (option) => {
		try {
			if (onUpdateItem) {
				const updatedItem = await onUpdateItem(option)
				if (updatedItem && updatedItem['value'] && updatedItem['label']) {
					const newOptions = currentOptions.map(item => {
						if (item.value === option.value) {
							return {
								value: updatedItem['value'],
								label: updatedItem['label']
							}
						}
						return item
					})
					setCurrentOptions(newOptions)
				}
			}
		} catch (e) {
			notification.error({
				message: 'Unable to save edited item',
				description: e.message
			})
		}
	}

	return (
		<div className={styles.customSelectContainer}>
			<p className={styles.customSelectTitle}>{title}</p>
			<Select
				style={style}
				loading={loading}
				placeholder={placeholder}
				className={`${small ? disabled ? 'custom-select-small-disabled' : 'custom-select-small' :
					disabled ? 'custom-select-disabled' : 'custom-select'} ${title && 'select-title-visible'}`}
				defaultValue={defaultValue}
				disabled={disabled}
				labelInValue={labelInValue}
				value={value}
				onChange={onChange}
				allowClear={allowClear}
				mode={mode}
				showSearch={showSearch}
				onSearch={onSearch}
				filterOption={filterOption}
				dropdownRender={menu => {
					return (
						<div>
							{menu}
							{
								addItem ?
									<Divider style={{ margin: '4px 0' }} /> : null
							}
							{
								addItem ?
									<div>
										{
											editItem ? null :
												<div className='add-item-option'>
													<Input
														value={addItemInputValue}
														onChange={e => {
															setAddItemInputValue(e.target.value)
														}}
													/>
													<Button
														icon={<PlusOutlined />}
														disabled={!addItemInputValue}
														loading={isAddingItem}
														type='text'
														onClick={onAddItemClick}
													>
														Add
													</Button>
												</div>
										}
										{
											options.length ?
												<div style={{ display: 'flex', justifyContent: 'center' }}>
													<Button
														type='link'
														onClick={() => {
															// clear selected value when edit mode is enabled
															onChange(null)
															setEditItem(!editItem)
														}}
													>
														{editItem ? 'Back to Normal Mode' : 'Enable Edit Mode'}
													</Button>
												</div> : null
										}
									</div> : null
							}
						</div>
					)
				}}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
			>
				{
					optGroupLabel ?
						<OptGroup label={optGroupLabel}>
							{
								currentOptions.map(option => {
									return (
										<Option
											className={unclipOptions ? 'full-custom-option' : ''}
											key={option.value}
											value={option.value}
											disabled={disabledOptions[option.value]}
										>{option.label}
										</Option>
									)
								})
							}
						</OptGroup> :
						currentOptions.map(option => {
							return (
								<Option
									className={unclipOptions ? 'full-custom-option' : ''}
									key={option.value}
									value={option.value}
									disabled={disabledOptions[option.value]}
								>
									{
										editItem ?
											<div onClick={e => e.stopPropagation()} className={styles.optionContainer}>
												<InlineTextEditor
													key={option.value}
													style={{ width: '100%' }}
													ref={ref => inputRefs.current[option.value] = ref}
													onClick={() => inputRefs.current[option.value]?.focus()}
													value={option.label}
													onSave={(label) => saveEditedItem({ value: option.value, label })}
												/>
											</div> : option.label
									}
								</Option>
							)
						})
				}
			</Select>
		</div>
	)
}

export default CustomSelect
