import { Button, Form, notification, Switch, Select, Input, Popconfirm, Radio } from 'antd'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Companies } from '../../services/api/companies'
import { SalesOrders } from '../../services/api/sales-orders'
import { updateCompanyDetails } from '../../store/companies/actions'
import { isValidUUID } from '../../utils'
import { claimsRoles } from '../../utils/constants'
import ImageUpload from '../image-upload'

export const Industry = {
	GENERAL: 'general',
	STEEL: 'steel',
	AGRICULTURE: 'agriculture'
}

const industryOptions = [
	{
		value: 'general',
		label: 'General'
	},
	{
		value: 'steel',
		label: 'Steel'
	},
	{
		value: 'agriculture',
		label: 'Agriculture'
	}
]

const CompanySettings = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const { query } = router
	const { params } = query
	const { userProfile, role } = useSelector(state => state.authReducer)
	const { companyDetails } = useSelector(state => state.companiesReducer)
	const [isDeliveryPlanEnabled, setIsDeliveryPlanEnabled] = useState(true)
	const [isPurchaseOrderEnabled, setIsPurchaseOrderEnabled] = useState(true)
	const [isFleetEnabled, setIsFleetEnabled] = useState(true)
	const [isVendorEnabled, setIsVendorEnabled] = useState(true)
	const [isInventoryEnabled, setIsInventoryEnabled] = useState(true)
	const [isPalletNumberEnabled, setIsPalletNumberEnabled] = useState(true)
	const [isStockMonitoringEnabled, setIsStockMonitoringEnabled] = useState(true)
	const [isSubStatusEnabled, setIsSubStatusEnabled] = useState(true)
	const [isSubOrdersEnabled, setIsSubOrdersEnabled] = useState(true)
	const [isInventoryUnderstockedEnabled, setIsInventoryUnderstockedEnabled] = useState(true)
	const [industry, setIndustry] = useState(industryOptions[0].value)
	const [logoUrl, setLogoUrl] = useState()
	const [apiKey, setApiKey] = useState()
	const [isEditSettingsEnabled, setIsEditSettingsEnabled] = useState(false)
	const [isUpdatingDetails, setIsUpdatingDetails] = useState(false)
	const [isUpdatingApiKey, setIsUpdatingApiKey] = useState(false)
	const [orderStatusFlows, setOrderStatusFlows] = useState([])
	const [orderStatusFlowKey, setOrderStatusFlowKey] = useState('DEFAULT')

	const getUUID = () => {
		if (params && params.length > 0) {
			if (isValidUUID(params[0])) {
				return params[0]
			} else {
				undefined
			}
		} else {
			return undefined
		}
	}

	const id = getUUID()
	const company = companyDetails[id]
	useEffect(() => {
		if (company) {
			setCompanyDetails()
		}
	}, [company])

	useEffect(() => {
		if (userProfile && role !== claimsRoles.ADMIN) {
			setCompanyDetails()
		}
	}, [userProfile])

	useEffect(() => {
		fetchOrderStatusFlows()
	}, [])

	const fetchOrderStatusFlows = async () => {
		const { data } = await SalesOrders.fetchOrderStatusFlows()
		setOrderStatusFlows(data)
	}

	const onSaveSettings = async () => {
		try {
			const id = getUUID()
			if (!id) {
				return
			}
			setIsUpdatingDetails(true)
			await dispatch(updateCompanyDetails(id, {
				isDeliveryPlanEnabled,
				isPurchaseOrderEnabled,
				isFleetEnabled,
				isInventoryEnabled,
				isPalletNumberEnabled,
				isStockMonitoringEnabled,
				isVendorEnabled,
				isSubStatusEnabled,
				isSubOrdersEnabled,
				isInventoryUnderstockedEnabled,
				orderStatusFlowKey,
				industry,
				logoUrl
			}))
			setIsEditSettingsEnabled(false)
			notification.success({
				message: 'Company Settings Updated',
				description: 'Company settings successfully changed.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Update Company Settings',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingDetails(false)
			setIsEditSettingsEnabled(false)
		}
	}

	const generateApiKey = async () => {
		try {
			const id = getUUID()
			const companyId = id ? id : userProfile.companyId
			setIsUpdatingApiKey(true)
			const { data } = await Companies.updateApiKey(companyId)
			setApiKey(data.apiKey)
			notification.success({
				message: 'Company API Key generated.',
				description: 'Company API Key successfully updated.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Generate API Key',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingApiKey(false)
		}
	}

	const setCompanyDetails = () => {
		const id = getUUID()
		const company = companyDetails[id]
		let companyDetail = undefined
		if (company) {
			companyDetail = company.companyDetails
		} else if (userProfile && userProfile.company) {
			companyDetail = userProfile.company.companyDetails
		}
		if (companyDetail) {
			setIndustry(companyDetail.industry.toLowerCase())
			setIsDeliveryPlanEnabled(companyDetail.isDeliveryPlanEnabled)
			setIsPurchaseOrderEnabled(companyDetail.isPurchaseOrderEnabled)
			setIsFleetEnabled(companyDetail.isFleetEnabled)
			setIsVendorEnabled(companyDetail.isVendorEnabled)
			setIsInventoryEnabled(companyDetail.isInventoryEnabled)
			setIsPalletNumberEnabled(companyDetail.isPalletNumberEnabled)
			setIsStockMonitoringEnabled(companyDetail.isStockMonitoringEnabled)
			setIsSubStatusEnabled(companyDetail.isSubStatusEnabled)
			setIsSubOrdersEnabled(companyDetail.isSubOrdersEnabled)
			setIsInventoryUnderstockedEnabled(companyDetail.isInventoryUnderstockedEnabled)
			setLogoUrl(companyDetail.logoUrl)
			setApiKey(companyDetail.apiKey)
			if (companyDetail.orderStatusFlow) {
				setOrderStatusFlowKey(companyDetail.orderStatusFlow.key)
			}
		} else {
			setIndustry(industryOptions[0].value)
			setIsDeliveryPlanEnabled(true)
		}
	}

	return (
		<Form style={{ display: 'flex', flexDirection: 'column', width: '560px', marginTop: 128 }}>
			{
				role === claimsRoles.ADMIN &&
					<>
						<h4 style={{ color: 'lightgray' }}>General</h4>
						<Form.Item
							label='Company Logo'
							tooltip='Edit the company logo that is displayed.'
						>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<ImageUpload
									title='Upload Logo'
									width={132}
									height={75}
									image={logoUrl}
									pathname='company-images'
									onImageUploaded={imageUrl => {
										setLogoUrl(imageUrl)
										setIsEditSettingsEnabled(true)
									}}
								/>
							</div>
						</Form.Item>
						<Form.Item
							label='Enabled/Disable Delivery Plans'
							tooltip='Enable/Disable delivery plan generation. Will hide delivery plans from all users within the company.'
						>
							<Switch
								checked={isDeliveryPlanEnabled}
								onChange={checked => {
									setIsDeliveryPlanEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disable Purchase Orders'
							tooltip='Enable/Disable purchase orders. Will disable the feature to create purchase orders.'
						>
							<Switch
								checked={isPurchaseOrderEnabled}
								onChange={checked => {
									setIsPurchaseOrderEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disable Fleet'
							tooltip='Enable/Disable fleet. Will hide fleet from all users within the company.'
						>
							<Switch
								checked={isFleetEnabled}
								onChange={checked => {
									setIsFleetEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disable Vendor'
							tooltip='Enable/Disable vendor. Will hide vendor from all users within the company.'
						>
							<Switch
								checked={isVendorEnabled}
								onChange={checked => {
									setIsVendorEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disable Inventory'
							tooltip='Enable/Disable inventory. Will hide inventory from all users within the company.'
						>
							<Switch
								checked={isInventoryEnabled}
								onChange={checked => {
									setIsInventoryEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disabled Pallet No.'
							tooltip='Enable/Disable Pallet No. Will hide Pallet No. from all users within the company.'
						>
							<Switch
								checked={isPalletNumberEnabled}
								onChange={checked => {
									setIsPalletNumberEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disabled Stock Monitoring.'
							tooltip='Enable/Disable Stock Monitoring. Will hide Stock Monitoring from all users within the company.'
						>
							<Switch
								checked={isStockMonitoringEnabled}
								onChange={checked => {
									setIsStockMonitoringEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disable Understocked Inventory'
							tooltip='Enable alerts that can indicate to a company that their inventory is understocked.'
						>
							<Switch
								checked={isInventoryUnderstockedEnabled}
								onChange={checked => {
									setIsInventoryUnderstockedEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disable Sub-Statuses'
							tooltip='Enable/Disable Sub-statuses. Will hide sub-statuses of orders from all users within the company.'
						>
							<Switch
								checked={isSubStatusEnabled}
								onChange={checked => {
									setIsSubStatusEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Enabled/Disable Sub-Order Creation'
							tooltip='Enable companies to create sub-orders from their orders.'
						>
							<Switch
								checked={isSubOrdersEnabled}
								onChange={checked => {
									setIsSubOrdersEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</Form.Item>
						<Form.Item
							label='Order Status Flows'
							tooltip='Changing the flow will affect the flow of the status of the orders.'
						>
							<Radio.Group
								style={{ display: 'flex', flexDirection: 'column' }}
								onChange={e => {
									setOrderStatusFlowKey(e.target.value)
									setIsEditSettingsEnabled(true)
								}}
								value={orderStatusFlowKey}
							>
								{
									orderStatusFlows.map((flow) => {
										return (
											<Radio key={flow.key} value={flow.key} style={{ marginBottom: '10px' }}>
												{
													flow.statuses.map((status, index) => {
														return (
															<span key={status} style={{ whiteSpace: 'nowrap' }}>
																{flow.labels[status]}
																{
																	flow.statuses.length > index + 1 ? ' -> ' : ''
																}
															</span>
														)
													})
												}
											</Radio>
										)
									})
								}
							</Radio.Group>
						</Form.Item>
						<Form.Item
							label='Industry'
							tooltip='Changing the industry will determine what features a company will have.'
						>
							<Select
								value={industry}
								onChange={value => {
									setIndustry(value)
									setIsEditSettingsEnabled(true)
								}}
								options={industryOptions}
							/>
						</Form.Item>
					</>
			}
			<h4 style={{ color: 'lightgray' }}>Integration</h4>
			<Form.Item
				label='API Key'
				tooltip='API key used to authenticate calls to the Nuport platform. Keep this key secure.'
			>
				<Input.Password
					disabled={apiKey === null || undefined}
					value={apiKey}
					placeholder='Generate a new API Key'
				/>
				<Popconfirm
					title={<div style={{ width: 200 }}>Are you sure you want to generate an API Key? This will make all current API Keys unusable.</div>}
					onConfirm={generateApiKey}
					okText='Yes'
					cancelText='No'
				>
					<Button
						loading={isUpdatingApiKey}
						size='small'
						style={{ marginTop: 12 }}
					>
						Generate
					</Button>
				</Popconfirm>
			</Form.Item>
			{
				isEditSettingsEnabled ?
					<Form.Item>
						<div style={{ display: 'flex' }}>
							<Button
								onClick={onSaveSettings}
								type='primary'
								loading={isUpdatingDetails}
							>
								Save
							</Button>
							<Button
								onClick={() => {
									setCompanyDetails()
									setIsEditSettingsEnabled(false)
								}}
								style={{ marginLeft: 12 }}
								type='ghost'
								loading={isUpdatingDetails}
							>
								Cancel
							</Button>
						</div>
					</Form.Item> : null
			}
		</Form>
	)
}

export default CompanySettings
