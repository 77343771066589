import { useState, useEffect } from 'react'
import {
	Button,
	Checkbox,
	Col,
	Divider,
	Modal,
	notification,
	Row,
	Space,
	Radio
} from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import moment from 'moment'
import 'moment-timezone'
import {
	EXPORT_TYPE,
	tabs,
	SALES_ORDER_APPROVED_STATUS
} from '../../pages/sales-orders'

export const ORDER_CREATION_DATE = 'createdAt'
export const ORDER_DATE = 'orderDate'
export const DELIVERED_DATE = 'deliveredAt'

import styles from './SalesOrdersExportModal.module.css'
import { SalesOrders } from '../../services/api/sales-orders'
import { Pdf } from '../../services/api/pdf'
import { useSelector } from 'react-redux'
import { SalesOrderType } from '../../utils/constants'

const SalesOrdersExportModal = ({
	visible,
	onCancel,
	exportType,
	filteredColumns
}) => {
	const { companyDetails } = useSelector((state) => state.authReducer)
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [statuses, setStatuses] = useState([SALES_ORDER_APPROVED_STATUS])
	const [isExporting, setIsExporting] = useState(false)
	const [dateRangeType, setDateRangeType] = useState(ORDER_DATE)
	const [orderType, setOrderType] = useState(undefined)
	const router = useRouter()
	const { query } = router
	const orderStatusFlow = companyDetails?.orderStatusFlow

	useEffect(() => {
		if (query.status) {
			setStatuses([query.status])
		}
	}, [query.status])

	const isFilterEnabled = () => {
		return fromDate && toDate && statuses.length !== 0
	}

	const onExport = async () => {
		try {
			if (!isFilterEnabled()) {
				return
			}
			const columns = Object.keys(filteredColumns)
				.filter((key) => filteredColumns[key])
				.join(',')
			const filters = {
				statuses,
				toDate: toDate ? moment(toDate).endOf('day').toDate() : null,
				fromDate: fromDate ? moment(fromDate).startOf('day').toDate() : null,
				type: orderType,
				dateRangeType,
				columns
			}
			setIsExporting(true)
			if (exportType == EXPORT_TYPE.CSV) {
				await SalesOrders.downloadCSV(filters)
			} else if (exportType == EXPORT_TYPE.PDF) {
				await Pdf.downloadSalesOrders(filters, moment.tz.guess())
			}
		} catch (e) {
			notification.error({
				message: `Unable to export ${exportType}`,
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsExporting(false)
			onCancel()
		}
	}

	const resetFilter = () => {
		setOrderType(undefined)
		setDateRangeType(null)
		setFromDate(null)
		setToDate(null)
		if (query.status) {
			setStatuses([query.status])
		} else {
			setStatuses([SALES_ORDER_APPROVED_STATUS])
		}
	}

	const onStatusesChange = (statuses) => {
		setStatuses(statuses)
	}

	const modifiedTabs = orderStatusFlow
		? tabs.filter((tab) => orderStatusFlow.statuses.includes(tab.status.toUpperCase()))
		: tabs

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={[
				<Button
					style={{
						marginRight: '200px',
						border: '2px solid #D9E0E9',
						fontWeight: 'bold'
					}}
					key='reset'
					type='primary'
					shape='round'
					size='large'
					onClick={resetFilter}
					ghost
				>
					Reset Filter
				</Button>,
				<Button
					style={{
						border: '2px solid #D9E0E9',
						fontWeight: 'bold'
					}}
					key='cancel'
					type='primary'
					shape='round'
					size='large'
					onClick={onCancel}
					ghost
				>
					Cancel
				</Button>,
				<Button
					style={{ fontWeight: 'bold' }}
					key='apply'
					type='primary'
					shape='round'
					size='large'
					loading={isExporting}
					disabled={!isFilterEnabled()}
					onClick={onExport}
				>
					{`Export ${exportType}`}
				</Button>
			]}
		>
			<div className={styles.exportModalContainer}>
				<div className={styles.section}>
					<Radio.Group
						style={{ display: 'flex' }}
						onChange={(e) => setOrderType(e.target.value)}
						value={orderType}
					>
						<Radio value={SalesOrderType.SO}>Sales Order</Radio>
						<Radio value={SalesOrderType.RO}>Return Order</Radio>
						<Radio value={SalesOrderType.STO}>
							Stock Transfer Order
						</Radio>
					</Radio.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Checkbox.Group
						value={[...statuses]}
						style={{ width: '100%' }}
						onChange={onStatusesChange}
					>
						<Row style={{ rowGap: '10px' }}>
							{modifiedTabs.map((tab) => {
								return (
									<Col key={tab.status} span={8}>
										<Checkbox value={tab.status}>
											{tab.title}
										</Checkbox>
									</Col>
								)
							})}
						</Row>
					</Checkbox.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Radio.Group
						style={{ display: 'flex' }}
						onChange={(e) => setDateRangeType(e.target.value)}
						value={!dateRangeType ? ORDER_DATE : dateRangeType}
					>
						<Radio checked value={ORDER_CREATION_DATE}>
							Creation Date
						</Radio>
						<Radio value={ORDER_DATE}>Order Date</Radio>
						<Radio value={DELIVERED_DATE}>Delivered Date</Radio>
					</Radio.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null}
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null}
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={(current) => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
			</div>
		</Modal>
	)
}

SalesOrdersExportModal.defaultProps = {
	exportType: ''
}

export default SalesOrdersExportModal
