import { getCloudFunctionBaseUrl } from '../../config'
import { get, post, patch, destroy } from './index'

export const Users = {
	index: ({
		page = 0,
		searchTerm,
		role,
		disabled,
		toDate,
		fromDate,
		permissionGroups,
		permissionGroupId
	} = {}) =>
		get('/users', {
			params: {
				searchTerm,
				page,
				role,
				disabled,
				toDate,
				fromDate,
				permissionGroups,
				permissionGroupId
			}
		}),
	single: (id) =>
		get(`/users/${id}`),
	create: ({ email, phone, name, role, internalId, permissionGroupId, locationIds, password } = {}) =>
		post('/users', { email, phone, name, role, internalId, permissionGroupId, locationIds, password }),
	signUp: (params) =>
		post('/users/sign-up', params),
	update: (id, {
		disabled,
		name,
		phone,
		email,
		internalId,
		role,
		locationIds
	} = {}) =>
		patch(`/users/${id}`, {
			disabled,
			name,
			phone,
			email,
			internalId,
			role,
			locationIds
		}),
	remove: (id) =>
		destroy(`/users/${id}`),
	fetchTeams: () => get('/users/teams'),
	fetchIndustries: () => get('/users/industries'),
	exists: ({ email, phone } = {}) => get('/users/exists', {
		params: {
			email,
			phone
		}
	}),
	forgotPassword: (email) => get('/forgotPassword', {
		baseURL: getCloudFunctionBaseUrl(),
		params: {
			email
		}
	})
}
