import { get, post } from './index'
import moment from 'moment'

export const Upload = {
	downloadProductsCsv: async () => {
		const response = await get('/upload/products/csv', {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `products_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	uploadProductsCsv: async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return await post('/upload/products/csv', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	},
	downloadDistributorsCsv: async () => {
		const response = await get('/upload/distributors/csv', {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `dealers_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	uploadDistributorsCsv: async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return await post('/upload/distributors/csv', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	},
	downloadOrdersCsv: async () => {
		const response = await get('/upload/sales-orders/csv', {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `orders_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	uploadOrdersCsv: async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return await post('/upload/sales-orders/csv', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	},
	downloadPurchaseOrdersCsv: async () => {
		const response = await get('/upload/purchase-orders/csv', {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `purchase_orders_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	uploadPurchaseOrdersCsv: async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return await post('/upload/purchase-orders/csv', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	},
	downloadVehiclesCsv: async () => {
		const response = await get('/upload/vehicles/csv', {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `fleet_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	uploadVehiclesCsv: async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return await post('/upload/vehicles/csv', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	},
	downloadInventoryCsv: async () => {
		const response = await get('/upload/inventory/csv', {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `inventory_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	uploadInventoryCsv: async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return await post('/upload/inventory/csv', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	},
	uploadOrderIdsCsv: async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return await post('/upload/sales-order-ids/csv', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	}
}
