import { get, post, destroy, patch } from './index'

export const Products = {
	index: ({
		searchTerm,
		page = 0,
		categoryIds,
		brandIds,
		originIds,
		types,
		includeCategory,
		inventory
	} = {}) =>
		get('/products', {
			params: {
				searchTerm,
				page,
				categoryIds,
				brandIds,
				includeCategory,
				inventory,
				types,
				originIds
			}
		}),
	fetchNewProductCode: () => get('/products/new-code'),
	single: (id, {
		inventory,
		packages
	} = {}) =>
		get(`/products/${id}`, {
			params: {
				inventory,
				packages
			}
		}),
	create: (params) =>
		post('/products', params),
	update: (id, params) =>
		patch(`/products/${id}`, params),
	getMetrics: () => get('/products/metrics'),
	remove: (id) =>
		destroy(`/products/${id}`),
	fetchCategories: ({
		searchTerm,
		page,
		parentIds,
		onlyChildren,
		onlyParents
	} = {}) => {
		return get('/products/categories', {
			params: {
				page,
				searchTerm,
				parentIds,
				onlyChildren,
				onlyParents
			}
		})
	},
	createCategory: ({ name, code, parentId } = {}) => {
		return post('/products/categories', {
			name,
			code,
			parentId
		})
	},
	fetchSalesOrderItems: (id, { page = 0 } = {}) =>
		get(`/products/${id}/sales-orders`, {
			params: {
				page
			}
		}),
	fetchPurchaseOrderItems: (id, { page = 0 } = {}) =>
		get(`/products/${id}/purchase-orders`, {
			params: {
				page
			}
		}),
	bulkUpdatePrices: async ({
		products = []
	} = {}) =>
		patch('/products/prices', {
			products
		}),
	fetchSpecifications: ({ type, isDefault }) =>
		get('/products/specifications', {
			params: {
				type,
				isDefault
			}
		}),
	fetchBrands: (params = {}) =>
		get('/products/brands', {
			params
		}),
	createBrand: ({ name } = {}) => {
		return post('/products/brands', {
			name
		})
	},
	updateBrand: (id, { name } = {}) => {
		return patch(`/products/brands/${id}`, {
			name
		})
	},
	createSpecification: (params) =>
		post('/products/specifications', params)
}
