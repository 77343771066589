import { convertWeightUnit } from './units'

export const getShortId = (salesOrder) => {
	const copyCount = `${salesOrder.copyCount > 0 ? `-${salesOrder.copyCount}` : ''}`
	if (salesOrder.referenceId) {
		return `${salesOrder.referenceId}${copyCount}`
	} else if (salesOrder.internalId) {
		return `${salesOrder.internalId}${copyCount}`
	} else {
		return `${salesOrder.type}-${salesOrder.shortId}${copyCount}`
	}
}

export const getSalesOrderWeightInTonnes = (salesOrder) => {
	const salesOrderItems = salesOrder.salesOrderItems || []
	let totalWeight = 0
	for (const salesOrderItem of salesOrderItems) {
		const weightInKgs = convertWeightUnit(+salesOrderItem.totalWeight, salesOrderItem.totalWeightUnit)
		totalWeight += weightInKgs
	}
	return (totalWeight / 1000).toFixed(2)
}

export const calculateSalesOrderMetrics = (salesOrders) => {
	let totalAmount = 0
	const distributorCountMap = {}
	const salesOrderItems = salesOrders
		.map(salesOrder => {
			if (salesOrder.distributor) {
				distributorCountMap[salesOrder.distributor.id] = true
			}
			totalAmount += +salesOrder.totalAmount
			return salesOrder.salesOrderItems
		})
		.reduce((a, b) => a.concat(b), [])
	let totalWeight = 0
	for (const salesOrderItem of salesOrderItems) {
		const weightInKgs = convertWeightUnit(+salesOrderItem?.totalWeight, salesOrderItem?.totalWeightUnit)
		totalWeight += weightInKgs
	}

	return {
		totalAmount,
		totalWeightInTonnes: totalWeight / 1000,
		distributorCount: Object.keys(distributorCountMap).length
	}
}
