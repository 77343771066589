export const parseCategory = (category) => {
	const results = []
	const traverse = (c) => {
		const { parent, ...rest } = c
		results.unshift(rest)
		if (parent) {
			traverse(parent)
		}
	}
	if (category) {
		traverse(category)
	}
	return results
}

export const buildCategoryOptions = (categories = []) => {
	const options = []
	const createOptions = (categories, options) => {
		categories.forEach(category => {
			const option = {
				value: category.id,
				label: category.code ? `${category.name} (Code: ${category.code})` : category.name,
				children: []
			}
			options.push(option)
			if (category.children?.length) {
				createOptions(category.children, option.children)
			}
		})
	}
	createOptions(categories, options)
	return options
}
