import { Users } from '../../services/api/users'
import { CREATE_USER_SUCCESS, FETCH_USERS_SUCCESS, FETCH_USER_DETAILS_SUCCESS, UPDATE_USER_SUCCESS } from './action-types'


export function fetchUsers ({ page, searchTerm, role, disabled, toDate, fromDate, permissionGroups }) {
	return async (dispatch) => {
		const response = await Users.index({ page, searchTerm, role, disabled, toDate, fromDate, permissionGroups })
		dispatch({ type: FETCH_USERS_SUCCESS, payload: { data: response.data } })
	}
}

export function fetchUserDetails (userId) {
	return async (dispatch) => {
		const response = await Users.single(userId)
		dispatch({ type: FETCH_USER_DETAILS_SUCCESS, payload: { data: response.data } })
	}
}

export function updateUser (uid, { disabled, email, phone, name, internalId, role, locationIds }) {
	return async (dispatch) => {
		const response = await Users.update(uid, { disabled, email, phone, name, internalId, role, locationIds })
		dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data })
	}
}

export function createUser ({ email, phone, name, role, internalId, permissionGroupId, locationIds, password }) {
	return async (dispatch) => {
		const response = await Users.create({ email, phone, name, role, internalId, permissionGroupId, locationIds, password })
		dispatch({ type: CREATE_USER_SUCCESS, payload: response.data })
	}
}
