import { useEffect, useState } from 'react'
import { Button, Divider, Modal, Space, notification } from 'antd'
import Input from '../input'
import SearchInput from '../search-input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './DistributorFilterModal.module.css'
import { Distributors } from '../../services/api/distributors'
import CustomSearchableSelect from '../custom-searchable-select'

const DistributorFilterModal = ({ visible, onCancel }) => {
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const [territories, setTerritories] = useState([])
	const [selectedTerritory, setSelectedTerritory] = useState(null)

	useEffect(() => {
		if (visible) {
			fetchTerritories()
		}
	}, [visible])

	const fetchTerritories = async () => {
		try {
			const { data } = await Distributors.getTerritories({ page: 0 })
			if (data.results) {
				setTerritories(data.results)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Territories!',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const isFilterEnabled = () => {
		return searchTerm || selectedTerritory || (fromDate && toDate)
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (fromDate && toDate) {
			searchParams.fromDate = fromDate
			searchParams.toDate = toDate
		}
		if (selectedTerritory) {
			searchParams.territoryId = selectedTerritory.value
		}

		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setSelectedTerritory(null)
		clearSearchParams()
	}

	const searchTerritories = async value => {
		return Distributors.getTerritories({ searchTerm: value, page: 0 })
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isFilterEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search, Dealer Name, Phone or Dealer ID'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Territory'
						searchTask={searchTerritories}
						defaultOptions={territories}
						isLoading={false}
						valueIndex='id'
						labelIndex='name'
						placeholder='Select Territory'
						onChange={location => setSelectedTerritory(location)}
						onClear={() => setSelectedTerritory(null)}
						style={{ width: '100%' }}
						value={selectedTerritory}
					/>
				</div>
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Sign Up Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
			</div>
		</Modal>
	)
}

export default DistributorFilterModal
