import { useEffect, useMemo, useState } from 'react'
import { Button, Divider, Modal, Space } from 'antd'
import SearchInput from '../search-input'
import useSearchParams from '../../hooks/useSearchParams'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import CustomSearchableSelect from '../custom-searchable-select'
import Input from '../input'
import moment from 'moment'
import { convertEnumToString, isNotEmpty } from '../../utils'
import { useSelector } from 'react-redux'
import CustomSelect from '../custom-select'
import styles from './inventory-order-movement-filter.module.css'

const DayShift = {
	MORNING: 'MORNING',
	EVENING: 'EVENING',
	NIGHT: 'NIGHT'
}

const dayShiftOptions = [
	{
		value: DayShift.MORNING,
		label: 'Morning'
	},
	{
		value: DayShift.EVENING,
		label: 'Evening'
	},
	{
		value: DayShift.NIGHT,
		label: 'Night'
	}
]

const orderTypeOptions = [
	{
		value: 'so',
		label: 'SO'
	},
	{
		value: 'sto',
		label: 'STO'
	}
]

const OrderStatus = {
	DELIVERED: 'DELIVERED',
	IN_TRANSIT: 'IN_TRANSIT'
}

const orderStatusOptions = [
	{
		value: OrderStatus.DELIVERED,
		label: 'Delivered'
	},
	{
		value: OrderStatus.IN_TRANSIT,
		label: 'In-Transit'
	}
]

const InventoryOrderMovementFilterModal = ({ visible, onCancel }) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const [selectedSourceLocations, setSelectedSourceLocations] = useState([])
	const [selectedDestinationLocations, setSelectedDestinationLocations] = useState([])
	const [sourceLocations, setSourceLocations] = useState([])
	const [destinationLocations, setDestinationLocations] = useState([])
	const [isLoadingSourceLocations, setIsLoadingSourceLocations] = useState(false)
	const [isLoadingDestinationLocations, setIsLoadingDestinationLocations] = useState(false)
	const [selectedOrderTypes, setSelectedOrderTypes] = useState([])
	const [selectedOrderStatuses, setSelectedOrderStatuses] = useState([])
	const [selectedDayShifts, setSelectedDayShifts] = useState([])
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [shelfLife, setShelfLife] = useState('')
	const isStockMonitoringEnabled = useMemo(() => companyDetails?.isStockMonitoringEnabled, [companyDetails])

	useEffect(() => {
		if (visible) {
			fetchInitialSourceLocations()
			fetchInitialDestinationLocations()
		}
	}, [visible])

	const fetchInitialSourceLocations = async () => {
		setIsLoadingSourceLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setSourceLocations(response.data.results)
		}
		setIsLoadingSourceLocations(false)
	}

	const fetchInitialDestinationLocations = async () => {
		setIsLoadingDestinationLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.BILLING] })
		if (response.data) {
			setDestinationLocations(response.data.results)
		}
		setIsLoadingDestinationLocations(false)
	}

	const searchSourceLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const searchDestinationLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.BILLING] })
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (selectedSourceLocations?.length) {
			searchParams.pickUpLocationIds = selectedSourceLocations.map(l => l.value)
		}
		if (selectedDestinationLocations?.length) {
			searchParams.dropOffLocationIds = selectedDestinationLocations.map(l => l.value)
		}
		if (selectedOrderTypes?.length) {
			searchParams.orderTypes = selectedOrderTypes
		}
		if (selectedDayShifts?.length) {
			searchParams.shifts = selectedDayShifts
		}
		if (fromDate) {
			searchParams.createdFrom = fromDate
		}
		if (toDate) {
			searchParams.createdTo = toDate
		}
		if (selectedOrderStatuses?.length === 1) {
			searchParams.isOrderDelivered = selectedOrderStatuses[0] === OrderStatus.DELIVERED
		} else {
			searchParams.isOrderDelivered = undefined
		}
		if (isNotEmpty(shelfLife)) {
			searchParams.shelfLife = +shelfLife
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setSelectedSourceLocations([])
		setFromDate(null)
		setToDate(null)
		setShelfLife('')
		setSelectedDayShifts([])
		setSelectedOrderTypes([])
		setSelectedDestinationLocations([])
		setSelectedOrderStatuses([])
		clearSearchParams()
	}

	const isResetButtonDisabled = () => {
		return !searchTerm && !selectedSourceLocations?.length && !selectedDestinationLocations?.length && !selectedOrderStatuses?.length && !selectedDayShifts?.length && !fromDate && !toDate && !selectedOrderTypes?.length && !shelfLife
	}

	const isApplyButtonEnabled = () => {
		return searchTerm || selectedSourceLocations?.length || selectedDestinationLocations?.length || selectedOrderTypes?.length || selectedDayShifts?.length || selectedOrderStatuses?.length || shelfLife || fromDate && toDate
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						disabled={isResetButtonDisabled()}
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isApplyButtonEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Product Name, Code, SO/STO Number, or Batch Number'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Source Locations'
						searchTask={searchSourceLocations}
						defaultOptions={sourceLocations}
						isLoading={isLoadingSourceLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						onChange={locations => setSelectedSourceLocations(locations)}
						onClear={() => setSelectedSourceLocations([])}
						style={{ width: '100%' }}
						value={selectedSourceLocations}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Destination Locations'
						searchTask={searchDestinationLocations}
						defaultOptions={destinationLocations}
						isLoading={isLoadingDestinationLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						onChange={locations => setSelectedDestinationLocations(locations)}
						onClear={() => setSelectedDestinationLocations([])}
						style={{ width: '100%' }}
						value={selectedDestinationLocations}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<CustomSelect
						title='Order Type'
						options={orderTypeOptions}
						onChange={setSelectedOrderTypes}
						onClear={() => setSelectedOrderTypes([])}
						style={{ width: '100%' }}
						value={selectedOrderTypes}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<CustomSelect
						title='Order Status'
						options={orderStatusOptions}
						onChange={setSelectedOrderStatuses}
						onClear={() => setSelectedOrderStatuses([])}
						style={{ width: '100%' }}
						value={selectedOrderStatuses}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Production Date</h3>
					<Space>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
				<div className={styles.section}>
					<CustomSelect
						title='Production Shift'
						options={dayShiftOptions}
						onChange={setSelectedDayShifts}
						onClear={() => setSelectedDayShifts([])}
						style={{ width: '100%' }}
						value={selectedDayShifts}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				{
					isStockMonitoringEnabled &&
						<div className={styles.section}>
							<Input
								title='Remaining Shelf Life in Days'
								type='text'
								style={{ width: '100%' }}
								value={shelfLife}
								onChange={(e) => {
									const value = e.target.value
									if (!isNaN(+value)) {
										setShelfLife(value)
									} else {
										setShelfLife('')
									}
								}}
							/>
						</div>
				}
			</div>
		</Modal>
	)
}

export default InventoryOrderMovementFilterModal
