import moment from 'moment'
import { convertEnumToString } from '.'
import { Category } from './inventory-products'

export const ReportTypes = {
	PRODUCTION: Category.PRODUCTION,
	UC_F_SS: Category.UC_F_SS,
	PRODUCTION_SUMMARY: 'PRODUCTION_SUMMARY',
	UC_F_SS_SUMMARY: 'UC_F_SS_SUMMARY',
	STORE_RECEIVED: 'STORE_RECEIVED',
	BATTERY_DISPATCH: 'BATTERY_DISPATCH',
	BATTERY_CONVERSION: 'BATTERY_CONVERSION',
	UNDER_DISTRIBUTION: 'UNDER_DISTRIBUTION',
	CURRENT_UNDER_DISTRIBUTION: 'CURRENT_UNDER_DISTRIBUTION',
	BATTERY_SUMMARY_ALL: 'BATTERY_SUMMARY_ALL',
	BATTERY_SUMMARY_BY_TYPE: 'BATTERY_SUMMARY_BY_TYPE',
	BATTERY_REJECT: 'BATTERY_REJECT',
	CURRENT_IN_STOCK: 'CURRENT_IN_STOCK',
	QUARANTINE: 'QUARANTINE',
	CURRENT_IN_STOCK_SUMMARY: 'CURRENT_IN_STOCK_SUMMARY',
	STORE_RECEIVED_SUMMARY: 'STORE_RECEIVED_SUMMARY',
	CURRENT_UNDER_DISTRIBUTION_SUMMARY: 'CURRENT_UNDER_DISTRIBUTION_SUMMARY',
	BATTERY_QUARANTINE_SUMMARY: 'BATTERY_QUARANTINE_SUMMARY',
	BATTERY_REJECT_SUMMARY: 'BATTERY_REJECT_SUMMARY',
	BATTERY_DISPATCH_SUMMARY: 'BATTERY_DISPATCH_SUMMARY',
	BATTERY_CONVERSION_SUMMARY: 'BATTERY_CONVERSION_SUMMARY',
	ORDERS_SUMMARY: 'ORDERS_SUMMARY'
}

export const DateRangeTypes = {
	PRODUCTION: 'PRODUCTION',
	PRODUCT_RECEIVED: 'PRODUCT_RECEIVED',
	PRODUCT_HANDOVER: 'PRODUCT_HANDOVER',
	UNDER_DISTRIBUTION: 'UNDER_DISTRIBUTION',
	CURRENT_UNDER_DISTRIBUTION: 'CURRENT_UNDER_DISTRIBUTION',
	CURRENT_IN_STOCK: 'CURRENT_IN_STOCK',
	BLOCKED: 'BLOCKED'
}

export const QuarantineTypes = {
	QUARANTINE: 'QUARANTINE',
	RETURN_FROM_QUARANTINE: 'RETURN_FROM_QUARANTINE'
}

export const getFormattedReportType = (type) => {
	switch (type) {
		case ReportTypes.UC_F_SS:
			return 'UC - F(SS)'
		case ReportTypes.UC_F_SS_SUMMARY:
			return 'UC - F(SS) Summary'
		case ReportTypes.BATTERY_REJECT:
			return 'Battery Damage Report'
		case ReportTypes.BATTERY_REJECT_SUMMARY:
			return 'Battery Damage Summary Report'
		case ReportTypes.BATTERY_QUARANTINE_SUMMARY:
			return 'Battery Blocked Summary Report'
		case ReportTypes.QUARANTINE:
			return 'Battery Blocked Report'
		case ReportTypes.ORDERS_SUMMARY:
			return 'Created Order Summary Report'
		default:
			return convertEnumToString(type ? type.toLowerCase() : '')
	}
}

export const getFormattedReportTitle = (report) => {
	let name = convertEnumToString(report.type ? report.type.toLowerCase() : '')
	switch (report.type) {
		case ReportTypes.BATTERY_REJECT:
			name = 'Battery Damage Report'
			break
		case ReportTypes.BATTERY_REJECT_SUMMARY:
			name = 'Battery Damage Summary Report'
			break
		case ReportTypes.QUARANTINE:
			name = 'Battery Blocked Report'
			break
		case ReportTypes.BATTERY_QUARANTINE_SUMMARY:
			name = 'Battery Blocked Summary Report'
			break
		case ReportTypes.ORDERS_SUMMARY:
			name = 'Created Order Summary Report'
			break
		default:
			break
	}
	const dateRange = `${moment(report.statistics?.startAt).format('MMM D, YYYY')}-to-${moment(report.statistics?.endAt).format('MMM D, YYYY')}`

	return `${name}-${dateRange}`
}

export const reportTypeOptions = [
	{
		label: 'Production Report',
		value: ReportTypes.PRODUCTION
	},
	{
		label: 'Production Report Summary',
		value: ReportTypes.PRODUCTION_SUMMARY
	},
	{
		label: 'UC - F(SS) Report',
		value: ReportTypes.UC_F_SS
	},
	{
		label: 'UC - F(SS) Report Summary',
		value: ReportTypes.UC_F_SS_SUMMARY
	},
	{
		label: 'Store Received',
		value: ReportTypes.STORE_RECEIVED
	},
	{
		label: 'Store Received Summary',
		value: ReportTypes.STORE_RECEIVED_SUMMARY
	},
	{
		label: 'Battery Dispatch',
		value: ReportTypes.BATTERY_DISPATCH
	},
	{
		label: 'Battery Dispatch Summary',
		value: ReportTypes.BATTERY_DISPATCH_SUMMARY
	},
	{
		label: 'Battery Conversion',
		value: ReportTypes.BATTERY_CONVERSION
	},
	{
		label: 'Battery Conversion Summary',
		value: ReportTypes.BATTERY_CONVERSION_SUMMARY
	},
	{
		label: 'Battery Damage Report',
		value: ReportTypes.BATTERY_REJECT
	},
	{
		label: 'Battery Damage Summary Report',
		value: ReportTypes.BATTERY_REJECT_SUMMARY
	},
	{
		label: 'Battery Under Distribution',
		value: ReportTypes.UNDER_DISTRIBUTION
	},
	{
		label: 'Current Under Distribution Battery',
		value: ReportTypes.CURRENT_UNDER_DISTRIBUTION
	},
	{
		label: 'Current Under Distribution Battery Summary',
		value: ReportTypes.CURRENT_UNDER_DISTRIBUTION_SUMMARY
	},
	{
		label: 'Battery Current Stock',
		value: ReportTypes.CURRENT_IN_STOCK
	},
	{
		label: 'Battery Current Stock Summary',
		value: ReportTypes.CURRENT_IN_STOCK_SUMMARY
	},
	{
		label: 'Battery Summary All',
		value: ReportTypes.BATTERY_SUMMARY_ALL
	},
	{
		label: 'Battery Summary By Type',
		value: ReportTypes.BATTERY_SUMMARY_BY_TYPE
	},
	{
		label: 'Battery Blocked Report',
		value: ReportTypes.QUARANTINE
	},
	{
		label: 'Battery Blocked Summary Report',
		value: ReportTypes.BATTERY_QUARANTINE_SUMMARY
	},
	{
		label: 'Created Order Summary',
		value: ReportTypes.ORDERS_SUMMARY
	}
]
