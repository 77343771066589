import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import useSearchParams from '../../hooks/useSearchParams'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import CustomSearchableSelect from '../custom-searchable-select'
import Input from '../input'
import moment from 'moment'
import { convertEnumToString } from '../../utils'
import CustomSelect from '../custom-select'
import styles from './inventory-scan-adherence-filter.module.css'
import { getShortId } from '../../utils/sales-orders'
import { SalesOrders } from '../../services/api/sales-orders'
import { SalesOrderType } from '../../utils/constants'

const orderTypeOptions = [
	{
		value: SalesOrderType.SO,
		label: 'SO'
	},
	{
		value: SalesOrderType.RO,
		label: 'RO'
	},
	{
		value: SalesOrderType.STO,
		label: 'STO'
	}
]


const InventoryScanAdherenceFilterModal = ({ visible, onCancel }) => {
	const { setSearchParams, clearSearchParams, isSearchApplied } = useSearchParams()
	const [selectedSourceLocations, setSelectedSourceLocations] = useState([])
	const [selectedDestinationLocations, setSelectedDestinationLocations] = useState([])
	const [selectedOrders, setSelectedOrders] = useState([])
	const [orders, setOrders] = useState([])
	const [sourceLocations, setSourceLocations] = useState([])
	const [destinationLocations, setDestinationLocations] = useState([])
	const [isLoadingSourceLocations, setIsLoadingSourceLocations] = useState(false)
	const [isLoadingOrders, setIsLoadingOrders] = useState(false)
	const [isLoadingDestinationLocations, setIsLoadingDestinationLocations] = useState(false)
	const [selectedOrderTypes, setSelectedOrderTypes] = useState([])
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)

	useEffect(() => {
		if (visible) {
			fetchInitialSourceLocations()
			fetchInitialDestinationLocations()
			fetchInitialOrders()
		}
	}, [visible])

	useEffect(() => {
		if (!isSearchApplied) {
			resetFilter()
		}
	}, [isSearchApplied])

	const fetchInitialSourceLocations = async () => {
		setIsLoadingSourceLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setSourceLocations(response.data.results)
		}
		setIsLoadingSourceLocations(false)
	}

	const fetchInitialDestinationLocations = async () => {
		setIsLoadingDestinationLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.BILLING] })
		if (response.data) {
			setDestinationLocations(response.data.results)
		}
		setIsLoadingDestinationLocations(false)
	}

	const fetchInitialOrders = async () => {
		setIsLoadingOrders(true)
		const response = await SalesOrders.index({ page: 0 })
		if (response.data) {
			setOrders(response.data.results)
		}
		setIsLoadingOrders(false)
	}

	const searchOrders = async value => {
		return SalesOrders.index({ searchTerm: value })
	}

	const searchSourceLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const searchDestinationLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.BILLING] })
	}

	const onApply = async () => {
		const searchParams = {}
		if (selectedSourceLocations?.length) {
			searchParams.pickUpLocationIds = selectedSourceLocations.map(l => l.value)
		}
		if (selectedDestinationLocations?.length) {
			searchParams.dropOffLocationIds = selectedDestinationLocations.map(l => l.value)
		}
		if (selectedOrders?.length) {
			searchParams.orderIds = selectedOrders.map(l => l.value)
		}
		if (selectedOrderTypes?.length) {
			searchParams.orderTypes = selectedOrderTypes
		}
		if (fromDate) {
			searchParams.orderDateStart = fromDate
		}
		if (toDate) {
			searchParams.orderDateEnd = toDate
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSelectedSourceLocations([])
		setFromDate(null)
		setToDate(null)
		setSelectedOrderTypes([])
		setSelectedDestinationLocations([])
		setSelectedOrders([])
		clearSearchParams()
	}

	const isResetButtonDisabled = () => {
		return !selectedOrders.length && !selectedSourceLocations?.length && !selectedDestinationLocations?.length && !fromDate && !toDate && !selectedOrderTypes?.length
	}

	const isApplyButtonEnabled = () => {
		return selectedOrders?.length || selectedSourceLocations?.length || selectedDestinationLocations?.length || selectedOrderTypes?.length || fromDate && toDate
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						disabled={isResetButtonDisabled()}
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isApplyButtonEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.section}>
					<h3 className={styles.label}>Destination Locations</h3>
					<CustomSearchableSelect
						searchTask={searchSourceLocations}
						defaultOptions={sourceLocations}
						isLoading={isLoadingSourceLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						onChange={locations => setSelectedSourceLocations(locations)}
						onClear={() => setSelectedSourceLocations([])}
						style={{ width: '100%' }}
						value={selectedSourceLocations}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Destination Locations</h3>
					<CustomSearchableSelect
						searchTask={searchDestinationLocations}
						defaultOptions={destinationLocations}
						isLoading={isLoadingDestinationLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						onChange={locations => setSelectedDestinationLocations(locations)}
						onClear={() => setSelectedDestinationLocations([])}
						style={{ width: '100%' }}
						value={selectedDestinationLocations}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Order Numbers</h3>
					<CustomSearchableSelect
						searchTask={searchOrders}
						defaultOptions={orders}
						isLoading={isLoadingOrders}
						customLabel={(data) => data.internalId ? data.internalId : getShortId(data)}
						valueIndex='id'
						onChange={locations => setSelectedOrders(locations)}
						onClear={() => setSelectedOrders([])}
						style={{ width: '100%' }}
						value={selectedOrders}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Order Types</h3>
					<CustomSelect
						options={orderTypeOptions}
						onChange={setSelectedOrderTypes}
						onClear={() => setSelectedOrderTypes([])}
						style={{ width: '100%' }}
						value={selectedOrderTypes}
						mode='multiple'
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Order Date</h3>
					<div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
						<Input
							style={{ flex: 1 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<div className={styles.dash} />
						<Input
							style={{ flex: 1 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default InventoryScanAdherenceFilterModal
