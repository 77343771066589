import { HYDRATE } from 'next-redux-wrapper'
import {
	ADD_PRODUCT_SUCCESS,
	FETCH_INVENTORY_SUCCESS,
	FETCH_INVENTORY_PRODUCTS_SUCCESS,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PRODUCT_CATEGORIES_SUCCESS,
	FETCH_PRODUCT_DETAILS_SUCCESS,
	FETCH_PRODUCT_METRICS_SUCCESS,
	FETCH_WAREHOUSE_DETAILS_SUCCESS,
	UPDATE_PRODUCT_SUCCESS,
	FETCH_INVENTORY_SKUS_SUCCESS,
	UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS,
	ADD_INVENTORY_PRODUCT_ITEM_SUCCESS,
	FETCH_INVENTORY_FEED_ITEMS_SUCCESS,
	FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS,
	ADD_INVENTORY_FEED_ITEM,
	FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS,
	FETCH_INVENTORY_STOCK_MONITORING_SUCCESS,
	FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS,
	SORT_INVENTORY_SKUS,
	FETCH_INVENTORY_SCAN_ADHERENCE_SUCCESS,
	SET_NEW_CATEGORY,
	PALLETIZE_INVENTORY_ITEMS_SUCCESS,
	UPDATE_INVENTORY_ITEMS_DISTRIBUTION_SUCCESS,
	RETURN_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	RESTOCK_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	DAMAGE_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	CONVERT_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	PRE_STOCK_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	FETCH_INVENTORY_PRODUCT_SUMMARY_ITEMS_SUCCESS,
	UNBLOCK_INVENTORY_ITEMS_SUCCESS,
	FETCH_INVENTORY_PRODUCT_COUNTS_SUCCESS,
	BLOCK_INVENTORY_ITEMS_SUCCESS
} from './action-types'
import { InventoryProductState } from '../../utils/constants'

const initialState = {
	totalCount: 0,
	page: 0,
	products: [],
	categories: [],
	productDetails: {},
	inventoryTotalCount: 0,
	inventoryPage: 0,
	warehouseDetails: {},
	inventory: [],
	inventoryProducts: [],
	inventoryProductsTotalCount: 0,
	inventoryProductsPage: 0,
	inventoryProductsGenerated: [],
	inventoryProductsBlocked: [],
	inventoryProductsDelivered: [],
	inventoryProductsReturned: [],
	inventoryProductsPreStock: [],
	inventoryProductsDamaged: [],
	inventorySkus: [],
	previousInventorySkus: [],
	inventorySkusTotalCount: 0,
	inventorySkusPage: 0,
	inventoryOnHandStock: [],
	inventoryOnHandStockTotalCount: 0,
	inventoryOnHandStockPage: 0,
	inventoryOrderMovement: [],
	inventoryOrderMovementTotalCount: 0,
	inventoryOrderMovementPage: 0,
	inventoryFeedItems: [],
	inventoryFeedItemMap: {},
	inventoryStockMonitoring: [],
	inventoryScanAdherence: [],
	inventoryProductSummaryItems: {
		[InventoryProductState.GENERATED]: [],
		[InventoryProductState.PRE_STOCK]: [],
		[InventoryProductState.IN_STOCK]: [],
		[InventoryProductState.UNDER_DISTRIBUTION]: [],
		[InventoryProductState.BLOCKED]: [],
		[InventoryProductState.DAMAGED]: []
	},
	inventoryProductItems: {
		[InventoryProductState.GENERATED]: [],
		[InventoryProductState.PRE_STOCK]: [],
		[InventoryProductState.IN_STOCK]: [],
		[InventoryProductState.UNDER_DISTRIBUTION]: [],
		[InventoryProductState.BLOCKED]: [],
		[InventoryProductState.DAMAGED]: [],
		[InventoryProductState.RETURNED]: [],
		[InventoryProductState.DELIVERED]: []
	},
	inventoryProductCounts: {
		[InventoryProductState.GENERATED]: 0,
		[InventoryProductState.PRE_STOCK]: 0,
		[InventoryProductState.IN_STOCK]: 0,
		[InventoryProductState.UNDER_DISTRIBUTION]: 0,
		[InventoryProductState.BLOCKED]: 0,
		[InventoryProductState.DAMAGED]: 0,
		[InventoryProductState.RETURNED]: 0,
		[InventoryProductState.DELIVERED]: 0
	},
	metrics: {
		new: 0,
		total: 0,
		active: 0
	}
}

const productsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_PRODUCTS_SUCCESS: {
			return {
				...state,
				products: payload.results,
				totalCount: payload.count,
				page: +payload.page
			}
		}
		case ADD_PRODUCT_SUCCESS: {
			const products = [payload, ...state.products]
			return {
				...state,
				products
			}
		}
		case UPDATE_PRODUCT_SUCCESS: {
			const newState = { ...state }
			const product = newState.productDetails[payload.id]
			if (product && payload) {
				const newProduct = Object.assign(product, payload)
				newState.productDetails[payload.id] = newProduct
			}
			newState.products = newState.products.map((product) => {
				return payload?.id === product.id ? Object.assign(product, payload) : product
			})
			return newState
		}
		case FETCH_PRODUCT_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: payload.results
			}
		case SET_NEW_CATEGORY: {
			const newCategories = [...state.categories]
			const setCategory = (categories, newCategory) => {
				if (newCategory.parentId) {
					categories.forEach(category => {
						if (category.id === newCategory.parentId) {
							if (!category.children?.length) {
								category.children = []
							}
							category.children.push(newCategory)
						} else if (category.children?.length) {
							setCategory(category.children, newCategory)
						}
					})
				} else {
					newCategories.unshift(newCategory)
				}
			}
			setCategory(newCategories, payload)
			return {
				...state,
				categories: newCategories
			}
		}
		case FETCH_PRODUCT_DETAILS_SUCCESS: {
			const newState = { ...state }
			const productDetail = payload.data
			newState.productDetails[productDetail.id] = productDetail
			return newState
		}
		case FETCH_INVENTORY_SUCCESS: {
			return {
				...state,
				inventory: payload.results,
				inventoryTotalCount: payload.count,
				inventoryPage: +payload.page
			}
		}
		case FETCH_INVENTORY_PRODUCTS_SUCCESS: {
			const data = payload.data
			switch (payload.state) {
				case InventoryProductState.GENERATED:
				case InventoryProductState.PRE_STOCK:
				case InventoryProductState.IN_STOCK:
				case InventoryProductState.UNDER_DISTRIBUTION:
				case InventoryProductState.BLOCKED:
				case InventoryProductState.DAMAGED:
				case InventoryProductState.RETURNED:
				case InventoryProductState.DELIVERED:
					return {
						...state,
						inventoryProductItems: {
							...state.inventoryProductItems,
							[payload.state]: data.results || []
						},
						totalCount: data.count,
						page: +data.page
					}
				default:
					return state
			}
		}
		case FETCH_INVENTORY_PRODUCT_SUMMARY_ITEMS_SUCCESS: {
			const data = payload.data
			switch (payload.state) {
				case InventoryProductState.GENERATED:
				case InventoryProductState.PRE_STOCK:
				case InventoryProductState.IN_STOCK:
				case InventoryProductState.UNDER_DISTRIBUTION:
				case InventoryProductState.BLOCKED:
				case InventoryProductState.DAMAGED:
					return {
						...state,
						inventoryProductSummaryItems: {
							...state.inventoryProductSummaryItems,
							[payload.state]: data.results || []
						},
						totalCount: data.count,
						page: +data.page
					}
				default:
					return state
			}
		}
		case FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS: {
			return {
				...state,
				inventoryOnHandStock: payload.results,
				inventoryOnHandStockTotalCount: payload.count,
				inventoryOnHandStockPage: +payload.page
			}
		}
		case FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS: {
			return {
				...state,
				inventoryOrderMovement: payload.results,
				inventoryOrderMovementTotalCount: payload.count,
				inventoryOrderMovementPage: +payload.page
			}
		}
		case FETCH_INVENTORY_SCAN_ADHERENCE_SUCCESS: {
			return {
				...state,
				inventoryScanAdherence: payload
			}
		}
		case FETCH_INVENTORY_STOCK_MONITORING_SUCCESS: {
			return {
				...state,
				inventoryStockMonitoring: payload
			}
		}
		case FETCH_INVENTORY_SKUS_SUCCESS: {
			return {
				...state,
				inventorySkus: payload,
				previousInventorySkus: payload
			}
		}
		case SORT_INVENTORY_SKUS: {
			const quantitySortingOrder = payload.quantitySortingOrder
			const columnKey = payload.columnKey
			let inventorySkus = [...state.inventorySkus]
			if (quantitySortingOrder === 'desc') {
				inventorySkus.sort((a, b) => {
					return +b[columnKey] - +a[columnKey]
				})
			} else if (quantitySortingOrder === 'asc') {
				inventorySkus.sort((a, b) => {
					return +a[columnKey] - +b[columnKey]
				})
			} else {
				inventorySkus = [...state.previousInventorySkus]
			}
			return {
				...state,
				inventorySkus
			}
		}
		case FETCH_INVENTORY_FEED_ITEMS_SUCCESS: {
			return {
				...state,
				inventoryFeedItems: payload
			}
		}
		case FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS: {
			const inventoryFeedItems = [...state.inventoryFeedItems, ...payload]
			return {
				...state,
				inventoryFeedItems
			}
		}
		case ADD_INVENTORY_FEED_ITEM: {
			if (state.inventoryFeedItemMap[payload.id]) {
				return state
			}
			const maxSize = Math.max(50, state.inventoryFeedItems.length)
			const inventoryFeedItems = [payload, ...state.inventoryFeedItems].slice(0, maxSize)
			return {
				...state,
				inventoryFeedItemMap: {
					...state.inventoryFeedItemMap,
					[payload.id]: true
				},
				inventoryFeedItems
			}
		}
		case FETCH_PRODUCT_METRICS_SUCCESS: {
			return {
				...state,
				metrics: payload
			}
		}
		case FETCH_WAREHOUSE_DETAILS_SUCCESS: {
			const newState = { ...state }
			const warehouseDetail = payload.data
			newState.warehouseDetails[warehouseDetail.id] = warehouseDetail
			return newState
		}
		case UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProductItems[InventoryProductState.IN_STOCK] = newState.inventoryProductItems[InventoryProductState.IN_STOCK].map(inventoryProduct =>
				payload.id === inventoryProduct.id ?
					Object.assign(inventoryProduct, payload) :
					inventoryProduct
			)
			return newState
		}
		case ADD_INVENTORY_PRODUCT_ITEM_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProductItems[InventoryProductState.IN_STOCK] = [payload, ...newState.inventoryProductItems[InventoryProductState.IN_STOCK]]
			newState.inventoryProductCounts[InventoryProductState.IN_STOCK]++
			return newState
		}
		case PRE_STOCK_INVENTORY_PRODUCT_ITEMS_SUCCESS: {
			const newState = { ...state }
			if (payload.batchNumber) {
				newState.inventoryProductSummaryItems[InventoryProductState.GENERATED] = newState.inventoryProductSummaryItems[InventoryProductState.GENERATED].filter(item => !(payload.batchNumber === item.batchNumber && payload.productId === item.productId))
				newState.inventoryProductCounts[InventoryProductState.GENERATED] -= payload.quantity
				newState.inventoryProductCounts[InventoryProductState.PRE_STOCK] += payload.quantity
			} else {
				newState.inventoryProductItems[InventoryProductState.GENERATED] = newState.inventoryProductItems[InventoryProductState.GENERATED].filter(item => !payload.serialNumbers.includes(item.serialNumber))
				newState.inventoryProductCounts[InventoryProductState.GENERATED] -= payload.serialNumbers.length
				newState.inventoryProductCounts[InventoryProductState.PRE_STOCK] += payload.serialNumbers.length
			}
			return newState
		}
		case PALLETIZE_INVENTORY_ITEMS_SUCCESS: {
			const newState = { ...state }
			if (payload.batchNumber) {
				newState.inventoryProductSummaryItems[InventoryProductState.PRE_STOCK] = newState.inventoryProductSummaryItems[InventoryProductState.PRE_STOCK].filter(item => !(payload.batchNumber === item.batchNumber && payload.productId === item.productId))
				newState.inventoryProductCounts[InventoryProductState.PRE_STOCK] -= payload.quantity
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] += payload.quantity
			} else {
				newState.inventoryProductItems[InventoryProductState.PRE_STOCK] = newState.inventoryProductItems[InventoryProductState.PRE_STOCK].filter(item => !payload.serialNumbers.includes(item.serialNumber))
				newState.inventoryProductCounts[InventoryProductState.PRE_STOCK] -= payload.serialNumbers.length
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] += payload.serialNumbers.length
			}
			return newState
		}
		case BLOCK_INVENTORY_ITEMS_SUCCESS: {
			const newState = { ...state }
			if (payload.batchNumber) {
				newState.inventoryProductSummaryItems[InventoryProductState.IN_STOCK] = newState.inventoryProductSummaryItems[InventoryProductState.IN_STOCK].filter(item => !(payload.batchNumber === item.batchNumber && payload.productId === item.productId))
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] -= payload.quantity
				newState.inventoryProductCounts[InventoryProductState.BLOCKED] += payload.quantity
			} else {
				newState.inventoryProductItems[InventoryProductState.IN_STOCK] = newState.inventoryProductItems[InventoryProductState.IN_STOCK].filter(item => !payload.serialNumbers.includes(item.serialNumber))
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] -= payload.serialNumbers.length
				newState.inventoryProductCounts[InventoryProductState.BLOCKED] += payload.serialNumbers.length
			}
			return newState
		}
		case UNBLOCK_INVENTORY_ITEMS_SUCCESS: {
			const newState = { ...state }
			if (payload.batchNumber) {
				newState.inventoryProductSummaryItems[InventoryProductState.BLOCKED] = newState.inventoryProductSummaryItems[InventoryProductState.BLOCKED].filter(item => !(payload.batchNumber === item.batchNumber && payload.productId === item.productId))
				newState.inventoryProductCounts[InventoryProductState.BLOCKED] -= payload.quantity
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] += payload.quantity
			} else {
				newState.inventoryProductItems[InventoryProductState.BLOCKED] = newState.inventoryProductItems[InventoryProductState.BLOCKED].filter(item => !payload.serialNumbers.includes(item.serialNumber))
				newState.inventoryProductCounts[InventoryProductState.BLOCKED] -= payload.serialNumbers.length
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] += payload.serialNumbers.length
			}
			return newState
		}
		case RESTOCK_INVENTORY_PRODUCT_ITEMS_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProductItems[InventoryProductState.DELIVERED] = newState.inventoryProductItems[InventoryProductState.DELIVERED].filter(item => !payload.serialNumbers.includes(item.serialNumber))
			newState.inventoryProductCounts[InventoryProductState.DELIVERED] -= payload.serialNumbers.length
			newState.inventoryProductCounts[InventoryProductState.IN_STOCK] += payload.serialNumbers.length
			return newState
		}
		case RETURN_INVENTORY_PRODUCT_ITEMS_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProductItems[InventoryProductState.DELIVERED] = newState.inventoryProductItems[InventoryProductState.DELIVERED].filter(item => !payload.serialNumbers.includes(item.serialNumber))
			newState.inventoryProductCounts[InventoryProductState.DELIVERED] -= payload.serialNumbers.length
			newState.inventoryProductCounts[InventoryProductState.RETURNED] += payload.serialNumbers.length
			return newState
		}
		case UPDATE_INVENTORY_ITEMS_DISTRIBUTION_SUCCESS: {
			const newState = { ...state }
			if (payload.batchNumber) {
				newState.inventoryProductSummaryItems[InventoryProductState.IN_STOCK] = newState.inventoryProductSummaryItems[InventoryProductState.IN_STOCK].filter(item => !(payload.batchNumber === item.batchNumber && payload.productId === item.productId))
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] -= payload.quantity
				newState.inventoryProductCounts[InventoryProductState.UNDER_DISTRIBUTION] += payload.quantity
			} else {
				newState.inventoryProductItems[InventoryProductState.IN_STOCK] = newState.inventoryProductItems[InventoryProductState.IN_STOCK].filter(item => !payload.serialNumbers.includes(item.serialNumber))
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] -= payload.serialNumbers.length
				newState.inventoryProductCounts[InventoryProductState.UNDER_DISTRIBUTION] += payload.serialNumbers.length
			}
			return newState
		}
		case DAMAGE_INVENTORY_PRODUCT_ITEMS_SUCCESS: {
			const newState = { ...state }
			if (payload.batchNumber) {
				newState.inventoryProductSummaryItems[InventoryProductState.IN_STOCK] = newState.inventoryProductSummaryItems[InventoryProductState.IN_STOCK].filter(item => !(payload.batchNumber === item.batchNumber && payload.productId === item.productId))
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] -= payload.quantity
				newState.inventoryProductCounts[InventoryProductState.DAMAGED] += payload.quantity
			} else {
				newState.inventoryProductItems[InventoryProductState.IN_STOCK] = newState.inventoryProductItems[InventoryProductState.IN_STOCK].filter(item => !payload.serialNumbers.includes(item.serialNumber))
				newState.inventoryProductCounts[InventoryProductState.IN_STOCK] -= payload.serialNumbers.length
				newState.inventoryProductCounts[InventoryProductState.DAMAGED] += payload.serialNumbers.length
			}
			return newState
		}
		case CONVERT_INVENTORY_PRODUCT_ITEMS_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProductItems[InventoryProductState.IN_STOCK] = newState.inventoryProductItems[InventoryProductState.IN_STOCK].map(item => {
				const updatedItem = payload.data.find(updatedItem => updatedItem.id === item.id)
				return updatedItem ? Object.assign(item, updatedItem) : item
			})
			return newState
		}
		case FETCH_INVENTORY_PRODUCT_COUNTS_SUCCESS: {
			const newState = { ...state }
			Object.assign(newState.inventoryProductCounts, payload)
			return newState
		}
		default:
			return state
	}
}

export default productsReducer
