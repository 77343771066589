import { useDispatch, useSelector } from 'react-redux'
import { setInventoryActionStates } from '../store/common/actions'

const useInventoryActions = () => {
	const { inventory } = useSelector(state => state.commonReducer)
	const dispatch = useDispatch()

	const setActionStates = (states = {}) => {
		dispatch(setInventoryActionStates(states))
	}

	const setSelectedSubTab = (tabValue = null) => {
		dispatch(setInventoryActionStates({ selectedSubTab: tabValue }))
	}

	const setSelectedSerialNumbers = (serialNumbers = []) => {
		dispatch(setInventoryActionStates({ selectedSerialNumbers: serialNumbers }))
	}

	const setSelectedItemIds = (itemIds = []) => {
		dispatch(setInventoryActionStates({ selectedItemIds: itemIds }))
	}

	const setSelectedItems = (items = []) => {
		dispatch(setInventoryActionStates({ selectedItems: items }))
	}

	const setViewType = (viewType) => {
		dispatch(setInventoryActionStates({ viewType }))
	}

	const setSummaryItem = (item) => {
		dispatch(setInventoryActionStates({ summaryItem: item }))
	}


	return {
		...inventory,
		setActionStates,
		setSelectedSubTab,
		setSelectedSerialNumbers,
		setSelectedItemIds,
		setSelectedItems,
		setViewType,
		setSummaryItem
	}
}

export default useInventoryActions
