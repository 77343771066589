import { useEffect, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import SearchInput from '../search-input'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './InventoryFilterModal.module.css'
import CustomSearchableSelect from '../custom-searchable-select'
import CustomSelect from '../custom-select'
import { Products } from '../../services/api/products'

const InventorySummaryFilterModal = ({ visible, onCancel }) => {
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams, isSearchApplied } = useSearchParams()
	const [isLoadingOrigins, setIsLoadingOrigins] = useState(false)
	const [isLoadingTypes, setIsLoadingTypes] = useState(false)
	const [isLoadingProducts, setIsLoadingProducts] = useState(false)
	const [origins, setOrigins] = useState([])
	const [types, setTypes] = useState([])
	const [selectedTypes, setSelectedTypes] = useState([])
	const [selectedOrigins, setSelectedOrigins] = useState([])
	const [products, setProducts] = useState([])
	const [selectedProduct, setSelectedProduct] = useState(null)

	useEffect(() => {
		if (visible) {
			fetchProducts()
			fetchOrigins()
		}
	}, [visible])

	useEffect(() => {
		if (selectedOrigins.length) {
			fetchTypes()
		}
	}, [selectedOrigins])

	useEffect(() => {
		if (!isSearchApplied) {
			resetFilter()
		}
	}, [isSearchApplied])

	const fetchProducts = async () => {
		try {
			setIsLoadingProducts(true)
			const { data } = await Products.index({ page: -1 })
			if (data.results) {
				setProducts(data.results)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Products!',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingProducts(false)
		}
	}

	const searchProducts = async value => {
		return Products.index({ searchTerm: value })
	}

	const fetchOrigins = async () => {
		setIsLoadingOrigins(true)
		const response = await Products.fetchCategories({ page: -1 })
		if (response.data) {
			setOrigins(response.data.results?.map((item) => ({ label: item.name, value: item.id })))
		}
		setIsLoadingOrigins(false)
	}

	const fetchTypes = async () => {
		setIsLoadingTypes(true)
		const response = await Products.fetchCategories({ page: -1, parentIds: selectedOrigins })
		if (response.data) {
			setTypes(response.data.results?.map((item) => ({ label: item.name, value: item.id })))
		}
		setIsLoadingTypes(false)
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (selectedOrigins?.length) {
			searchParams.originIds = selectedOrigins
		}
		if (selectedTypes?.length) {
			searchParams.typeIds = selectedTypes
		}
		if (selectedProduct) {
			searchParams.productId = selectedProduct.value
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setSelectedOrigins([])
		setSelectedTypes([])
		clearSearchParams()
		setSelectedProduct(null)
	}

	const isResetButtonDisabled = () => {
		return !searchTerm && selectedOrigins.length === 0 && selectedTypes.length === 0 && !selectedProduct
	}

	const isApplyButtonEnabled = () => {
		return searchTerm || selectedOrigins.length || selectedTypes.length || selectedProduct
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						disabled={isResetButtonDisabled()}
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isApplyButtonEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Batch Number'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<CustomSearchableSelect
						title='Product'
						searchTask={searchProducts}
						defaultOptions={products}
						isLoading={isLoadingProducts}
						customLabel={(data) => `${data.name} - ${data.code}`}
						valueIndex='id'
						placeholder='Select Product'
						onChange={product => setSelectedProduct(product)}
						onClear={() => setSelectedProduct(null)}
						style={{ width: 494 }}
						value={selectedProduct}
						allowClear={true}
					/>
				</div>
				<div className={styles.section}>
					<CustomSelect
						style={{ width: '100%' }}
						title='Origin'
						loading={isLoadingOrigins}
						options={origins}
						value={selectedOrigins}
						onChange={setSelectedOrigins}
						allowClear={true}
						mode='multiple'
					/>
				</div>
				<div className={styles.section}>
					<CustomSelect
						style={{ width: '100%' }}
						title='Types'
						loading={isLoadingTypes}
						options={types}
						value={selectedTypes}
						onChange={setSelectedTypes}
						allowClear={true}
						mode='multiple'
					/>
				</div>
			</div>
		</Modal>
	)
}

export default InventorySummaryFilterModal
