import { Button, Input, Space } from 'antd'
import { forwardRef, useState } from 'react'
import { EditOutlined } from '@ant-design/icons'

const InlineTextEditor = forwardRef(({ value, onChange, onClick, onSave, onCancel, style = {} }, ref) => {
	const [editMode, setEditMode] = useState(false)
	const [currentValue, setCurrentValue] = useState(value)
	const [loading, setLoading] = useState(false)

	const save = async () => {
		try {
			setLoading(true)
			await onSave(currentValue)
			setEditMode(false)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div style={{ ...style, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
			{
				editMode ?
					<Input
						ref={ref}
						onClick={onClick}
						autoFocus={true}
						defaultValue={currentValue}
						onChange={(e) => {
							setCurrentValue(e.target.value)
							onChange?.(e)
						}}
					/> : <div>{value}</div>
			}
			<div>
				{
					!editMode ?
						<Button
							type='link'
							icon={<EditOutlined />}
							onClick={() => setEditMode(true)}
						/> :
						<Space style={{ marginLeft: '8px' }}>
							<Button loading={loading} onClick={save} type='primary' size='small'>
								Save
							</Button>
							<Button disabled={loading} size='small' onClick={() => {
								setEditMode(false)
								setCurrentValue(value)
								onCancel?.()
							}}
							>
								Cancel
							</Button>
						</Space>
				}
			</div>
		</div>
	)
})

export default InlineTextEditor
