import { SET_INVENTORY_ACTION_STATES, SET_SEARCH_PARAMS } from './action-types'


export function setSearchParams (params) {
	return (dispatch) => {
		dispatch({ type: SET_SEARCH_PARAMS, payload: { ...params } })
	}
}

export function setInventoryActionStates (states = {}) {
	return (dispatch) => {
		dispatch({ type: SET_INVENTORY_ACTION_STATES, payload: { ...states } })
	}
}
