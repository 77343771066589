import { Products } from '../../services/api/products'
import { Inventory } from '../../services/api/inventory'
import { InventoryProductItems } from '../../services/api/firebase'
import {
	ADD_PRODUCT_SUCCESS,
	FETCH_INVENTORY_SUCCESS,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PRODUCT_CATEGORIES_SUCCESS,
	FETCH_PRODUCT_DETAILS_SUCCESS,
	FETCH_PRODUCT_METRICS_SUCCESS,
	FETCH_WAREHOUSE_DETAILS_SUCCESS,
	UPDATE_PRODUCT_SUCCESS,
	FETCH_INVENTORY_PRODUCTS_SUCCESS,
	FETCH_INVENTORY_SKUS_SUCCESS,
	UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS,
	ADD_INVENTORY_PRODUCT_ITEM_SUCCESS,
	FETCH_INVENTORY_FEED_ITEMS_SUCCESS,
	ADD_INVENTORY_FEED_ITEM,
	FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS,
	FETCH_INVENTORY_STOCK_MONITORING_SUCCESS,
	FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS,
	FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS,
	BLOCK_INVENTORY_ITEMS_SUCCESS,
	SORT_INVENTORY_SKUS,
	FETCH_INVENTORY_SCAN_ADHERENCE_SUCCESS,
	SET_NEW_CATEGORY,
	PALLETIZE_INVENTORY_ITEMS_SUCCESS,
	UPDATE_INVENTORY_ITEMS_DISTRIBUTION_SUCCESS,
	RETURN_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	RESTOCK_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	DAMAGE_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	CONVERT_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	PRE_STOCK_INVENTORY_PRODUCT_ITEMS_SUCCESS,
	FETCH_INVENTORY_PRODUCT_SUMMARY_ITEMS_SUCCESS,
	UNBLOCK_INVENTORY_ITEMS_SUCCESS,
	FETCH_INVENTORY_PRODUCT_COUNTS_SUCCESS
} from './action-types'

export function fetchProducts ({
	searchTerm,
	page = 0,
	categoryIds,
	brandIds,
	includeCategory
} = {}) {
	return async (dispatch) => {
		const response = await Products.index({
			searchTerm,
			page,
			categoryIds,
			brandIds,
			includeCategory
		})
		dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: response.data })
	}
}

export function addProduct (params) {
	return async (dispatch) => {
		const response = await Products.create(params)
		dispatch({ type: ADD_PRODUCT_SUCCESS, payload: response.data })
	}
}

export function updateProduct (id, params) {
	return async (dispatch) => {
		const response = await Products.update(id, params)
		dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: response.data })
	}
}

export function fetchProductCategories (params = {}) {
	return async (dispatch) => {
		const response = await Products.fetchCategories(params)
		dispatch({ type: FETCH_PRODUCT_CATEGORIES_SUCCESS, payload: response.data })
	}
}

export function setCategory (newCategory) {
	return async (dispatch) => {
		dispatch({ type: SET_NEW_CATEGORY, payload: newCategory })
	}
}

export function fetchProductDetails (id) {
	return async (dispatch) => {
		const response = await Products.single(id, {
			inventory: true,
			packages: true
		})
		dispatch({ type: FETCH_PRODUCT_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function fetchInventory ({
	page = 0,
	searchTerm,
	locationId,
	updatedFrom,
	updatedTo,
	minQuantity,
	maxQuantity
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.indexInventory({
			page,
			searchTerm,
			locationId,
			updatedFrom,
			updatedTo,
			minQuantity,
			maxQuantity
		})
		dispatch({ type: FETCH_INVENTORY_SUCCESS, payload: response.data })
	}
}

export function fetchInventoryProducts ({
	page = 0,
	pageSize = 50,
	searchTerm,
	locationId,
	updatedFrom,
	updatedTo,
	state,
	isOrdered,
	skus,
	createdFrom,
	createdTo,
	chargeTypes,
	originIds,
	typeIds,
	productId,
	dayShifts,
	remark
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.indexInventoryProducts({
			page,
			searchTerm,
			locationId,
			updatedFrom,
			updatedTo,
			state,
			isOrdered,
			skus,
			createdFrom,
			createdTo,
			chargeTypes,
			originIds,
			typeIds,
			remark,
			pageSize,
			productId,
			dayShifts
		})
		dispatch({ type: FETCH_INVENTORY_PRODUCTS_SUCCESS, payload: { data: response.data, state } })
	}
}

export function fetchInventoryProductSummaryItems ({
	page = 0,
	pageSize = 50,
	searchTerm,
	state,
	productId,
	typeIds,
	originIds
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.fetchInventoryProductSummaryItems({
			page,
			pageSize,
			searchTerm,
			state,
			productId,
			typeIds,
			originIds
		})
		dispatch({ type: FETCH_INVENTORY_PRODUCT_SUMMARY_ITEMS_SUCCESS, payload: { data: response.data, state } })
	}
}

export function fetchInventoryOnHandStock (
	{
		page = 0,
		searchTerm,
		updatedFrom,
		updatedTo,
		productIds,
		skus,
		createdFrom,
		createdTo,
		shifts,
		batchNumbers,
		machineCodes,
		shelfLife,
		facilities,
		sortInTransitQuantity,
		sortOnHandStockQuantity
	} = {}
) {
	return async (dispatch) => {
		const response = await Inventory.indexInventoryOnHandStock({
			page,
			searchTerm,
			updatedFrom,
			updatedTo,
			productIds,
			skus,
			createdFrom,
			createdTo,
			shifts,
			batchNumbers,
			machineCodes,
			shelfLife,
			facilities,
			sortInTransitQuantity,
			sortOnHandStockQuantity
		})
		dispatch({ type: FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS, payload: response.data })
	}
}

export function fetchInventorySkus (params = {}) {
	return async (dispatch) => {
		const response = await Inventory.fetchProductTotals(params)
		dispatch({ type: FETCH_INVENTORY_SKUS_SUCCESS, payload: response.data })
	}
}

export function sortInventorySkus ({
	quantitySortingOrder,
	columnKey
} = {}) {
	return async (dispatch) => {
		dispatch({ type: SORT_INVENTORY_SKUS, payload: { quantitySortingOrder, columnKey } })
	}
}

export function fetchProductMetrics () {
	return async (dispatch) => {
		const response = await Products.getMetrics()
		dispatch({ type: FETCH_PRODUCT_METRICS_SUCCESS, payload: response.data })
	}
}

export function fetchInventoryStockMonitoringData (params) {
	return async (dispatch) => {
		const response = await Inventory.fetchInventoryStockMonitoring(params)
		dispatch({ type: FETCH_INVENTORY_STOCK_MONITORING_SUCCESS, payload: response.data })
	}
}

export function fetchInventoryOrderMovement (params) {
	return async (dispatch) => {
		const response = await Inventory.indexInventoryOrderMovement(params)
		dispatch({ type: FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS, payload: response.data })
	}
}

export function fetchInventoryScanAdherence (params) {
	return async (dispatch) => {
		const response = await Inventory.indexInventoryScanAdherence(params)
		dispatch({ type: FETCH_INVENTORY_SCAN_ADHERENCE_SUCCESS, payload: response.data })
	}
}

export function fetchWarehouseDetails (id) {
	return async (dispatch) => {
		const response = await Inventory.singleWarehouse(id)
		dispatch({ type: FETCH_WAREHOUSE_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateInventoryProductItem (payload) {
	return (dispatch) => {
		dispatch({ type: UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS, payload })
	}
}

export function addInventoryProductItem (payload) {
	return (dispatch) => {
		dispatch({ type: ADD_INVENTORY_PRODUCT_ITEM_SUCCESS, payload })
	}
}

export function fetchInventoryProductItemHistory (cid) {
	return async (dispatch) => {
		const inventoryProductHistoryItems = await InventoryProductItems.fetchInventoryProductItemHistoryOnce(cid)
		dispatch({ type: FETCH_INVENTORY_FEED_ITEMS_SUCCESS, payload: inventoryProductHistoryItems })
	}
}

export function fetchMoreInventoryProductItemHistory (cid, lastDocumentId) {
	return async (dispatch) => {
		const inventoryProductHistoryItems = await InventoryProductItems.fetchMoreInventoryProductItemHistory(cid, lastDocumentId)
		dispatch({ type: FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS, payload: inventoryProductHistoryItems })
	}
}

export function addInventoryProductItemHistory (payload) {
	return async (dispatch) => {
		dispatch({ type: ADD_INVENTORY_FEED_ITEM, payload })
	}
}

export function blockInventoryProducts ({
	blocked,
	serialNumbers = [],
	remark,
	batchNumber,
	productId,
	quantity
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.blockInventoryProducts({
			blocked,
			serialNumbers,
			remark,
			batchNumber,
			productId
		})
		dispatch({ type: blocked ? BLOCK_INVENTORY_ITEMS_SUCCESS : UNBLOCK_INVENTORY_ITEMS_SUCCESS, payload: { data: response.data, batchNumber, serialNumbers, productId, quantity } })
	}
}

export function palletizeInventoryProducts ({
	serialNumbers,
	batchNumber,
	state,
	productId,
	quantity
}) {
	return async (dispatch) => {
		const response = await Inventory.palletizeInventoryItems({
			serialNumbers,
			batchNumber,
			state,
			productId
		})
		dispatch({ type: PALLETIZE_INVENTORY_ITEMS_SUCCESS, payload: { data: response.data, serialNumbers, batchNumber, state, productId, quantity } })
	}
}

export function updateInventoryProductsToDistribution ({
	serialNumbers,
	batchNumber,
	state,
	productId,
	quantity
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.updateInventoryItemsToDistribution({
			serialNumbers,
			batchNumber,
			state,
			productId
		})
		dispatch({ type: UPDATE_INVENTORY_ITEMS_DISTRIBUTION_SUCCESS, payload: { data: response.data, serialNumbers, batchNumber, state, productId, quantity } })
	}
}

export function returnInventoryProductItems (locationId, serialNumbers = []) {
	return async (dispatch) => {
		const response = await Inventory.returnInventoryProductItems(locationId, serialNumbers)
		dispatch({ type: RETURN_INVENTORY_PRODUCT_ITEMS_SUCCESS, payload: { data: response.data, serialNumbers } })
	}
}

export function convertInventoryProductItems ({
	productId,
	serialNumbers,
	chargeType
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.convertInventoryProductItems({
			productId,
			serialNumbers,
			chargeType
		})
		dispatch({ type: CONVERT_INVENTORY_PRODUCT_ITEMS_SUCCESS, payload: { data: response.data, serialNumbers } })
	}
}

export function restockInventoryProductItems (serialNumbers = []) {
	return async (dispatch) => {
		const response = await Inventory.restockInventoryProductItems(serialNumbers)
		dispatch({ type: RESTOCK_INVENTORY_PRODUCT_ITEMS_SUCCESS, payload: { data: response.data, serialNumbers } })
	}
}

export function preStockInventoryProductItems ({
	serialNumbers,
	batchNumber,
	state,
	productId,
	quantity
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.preStockInventoryProductItems({ serialNumbers, batchNumber, state, productId })
		dispatch({ type: PRE_STOCK_INVENTORY_PRODUCT_ITEMS_SUCCESS, payload: { data: response.data, serialNumbers, batchNumber, state, productId, quantity } })
	}
}

export function damageInventoryProductItems (serialNumbers = []) {
	return async (dispatch) => {
		const response = await Inventory.damageInventoryProductItems(serialNumbers)
		dispatch({ type: DAMAGE_INVENTORY_PRODUCT_ITEMS_SUCCESS, payload: { data: response.data, serialNumbers } })
	}
}

export function fetchInventoryProductCounts (params) {
	return async (dispatch) => {
		const response = await Inventory.fetchInventoryProductCounts(params)
		dispatch({ type: FETCH_INVENTORY_PRODUCT_COUNTS_SUCCESS, payload: response.data })
	}
}
