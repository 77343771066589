import { useMemo, useState } from 'react'
import { Button, Divider, Modal, Space } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import SearchInput from '../search-input'
import moment from 'moment'
import Select from '../../components/custom-select'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './ReportFilterModal.module.css'
import { paths } from '../../utils/constants'
import { ReportTypes, reportTypeOptions } from '../../utils/reports'
import { useSelector } from 'react-redux'


const ReportFilterModal = ({ visible, onCancel }) => {
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [type, setType] = useState()
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const router = useRouter()
	const { permissions } = useSelector(state => state.authReducer)

	const modifiedReportTypeOptions = useMemo(() => {
		return reportTypeOptions.filter(option => {
			switch (option.value) {
				case ReportTypes.PRODUCTION:
					return permissions?.viewProductionReports
				case ReportTypes.UC_F_SS:
					return permissions?.viewUcFssReports
				case ReportTypes.STORE_RECEIVED:
					return permissions?.viewStoreReceivedReports
				case ReportTypes.BATTERY_DISPATCH:
					return permissions?.viewBatteryDispatchReports
				case ReportTypes.BATTERY_CONVERSION:
					return permissions?.viewBatteryConversionReports
				case ReportTypes.BATTERY_REJECT:
					return permissions?.viewBatteryRejectReports
				case ReportTypes.UNDER_DISTRIBUTION:
					return permissions?.viewBatteryUnderDistributionReports
				case ReportTypes.CURRENT_UNDER_DISTRIBUTION:
					return permissions?.viewCurrentUnderDistributionBatteryReports
				case ReportTypes.CURRENT_IN_STOCK:
					return permissions?.viewBatteryCurrentStockReports
				case ReportTypes.BATTERY_SUMMARY_ALL:
					return permissions?.viewBatterySummaryAllReports
				case ReportTypes.BATTERY_SUMMARY_BY_TYPE:
					return permissions?.viewBatterySummaryByTypeReports
				case ReportTypes.QUARANTINE:
					return permissions?.viewBatteryQuarantineReports
				case ReportTypes.PRODUCTION_SUMMARY:
					return permissions?.viewProductionReportSummaryReports
				case ReportTypes.UC_F_SS_SUMMARY:
					return permissions?.viewUcFssReportSummaryReports
				case ReportTypes.CURRENT_IN_STOCK_SUMMARY:
					return permissions?.viewBatteryCurrentStockSummaryReports
				case ReportTypes.STORE_RECEIVED_SUMMARY:
					return permissions?.viewStoreReceivedSummaryReports
				case ReportTypes.CURRENT_UNDER_DISTRIBUTION_SUMMARY:
					return permissions?.viewCurrentUnderDistributionSummaryReports
				case ReportTypes.BATTERY_QUARANTINE_SUMMARY:
					return permissions?.viewBatteryQuarantineSummaryReports
				case ReportTypes.BATTERY_DISPATCH_SUMMARY:
					return permissions?.viewBatteryDispatchSummaryReports
				case ReportTypes.BATTERY_REJECT_SUMMARY:
					return permissions?.viewBatteryRejectSummaryReports
				case ReportTypes.BATTERY_CONVERSION_SUMMARY:
					return permissions?.viewBatteryConversionSummaryReports
				case ReportTypes.ORDERS_SUMMARY:
					return permissions?.viewCreatedOrderSummaryReports
				default:
					return false
			}
		})
	}, [permissions])

	const isFilterEnabled = () => {
		return searchTerm || (fromDate && toDate) || type
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (fromDate && toDate) {
			searchParams.fromDate = fromDate
			searchParams.toDate = toDate
		}
		if (type) {
			searchParams.type = type
		}

		setSearchParams(searchParams)
		router.push(paths.REPORTS)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setType()
		clearSearchParams()
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isFilterEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Report ID'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Creation Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
				<div className='section'>
					<h3 className={styles.label}>Report Type</h3>
					<Select
						style={{ width: '100%' }}
						onChange={option => setType(option)}
						value={type}
						placeholder='Select Report Type'
						options={modifiedReportTypeOptions}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ReportFilterModal
