import moment from 'moment'
import { convertEnumToString } from '.'
import { ChargeType } from './constants'

const packCodeMorningShiftIndicatorMap = {
	'11' : '1',
	'14' : '2',
	'17' : '3',
	'21' : '4',
	'24' : '5',
	'27' : '6',
	'31' : '7',
	'34' : '8',
	'37' : '9',
	'41' : '10',
	'44' : '11',
	'47' : '12',
	'51' : '13',
	'54' : '14',
	'57' : '15',
	'61' : '16',
	'64' : '17',
	'67' : '18',
	'71' : '19',
	'74' : '20',
	'77' : '21',
	'81' : '22',
	'84' : '23',
	'87' : '24',
	'91' : '25',
	'94' : '26',
	'97' : '27',
	'01' : '28',
	'04' : '29',
	'07' : '30',
	'10' : '31'
}

const packCodeEveningShiftIndicatorMap = {
	'12' : '1',
	'15' : '2',
	'18' : '3',
	'22' : '4',
	'25' : '5',
	'28' : '6',
	'32' : '7',
	'35' : '8',
	'38' : '9',
	'42' : '10',
	'45' : '11',
	'48' : '12',
	'52' : '13',
	'55' : '14',
	'58' : '15',
	'62' : '16',
	'65' : '17',
	'68' : '18',
	'72' : '19',
	'75' : '20',
	'78' : '21',
	'82' : '22',
	'85' : '23',
	'88' : '24',
	'92' : '25',
	'95' : '26',
	'98' : '27',
	'02' : '28',
	'05' : '29',
	'08' : '30',
	'20' : '31'
}

const packCodeNightShiftIndicatorMap = {
	'13' : '1',
	'16' : '2',
	'19' : '3',
	'23' : '4',
	'26' : '5',
	'29' : '6',
	'33' : '7',
	'36' : '8',
	'39' : '9',
	'43' : '10',
	'46' : '11',
	'49' : '12',
	'53' : '13',
	'56' : '14',
	'59' : '15',
	'63' : '16',
	'66' : '17',
	'69' : '18',
	'73' : '19',
	'76' : '20',
	'79' : '21',
	'83' : '22',
	'86' : '23',
	'89' : '24',
	'93' : '25',
	'96' : '26',
	'99' : '27',
	'03' : '28',
	'06' : '29',
	'09' : '30',
	'30' : '31'
}

export const Category = {
	PRODUCTION: 'PRODUCTION',
	UC_F_SS: 'UC_F_SS'
}

export const getFormattedInventoryProductCategory = (category) => {
	switch (category) {
		case Category.UC_F_SS:
			return 'UC - F(SS)'
		default:
			return convertEnumToString(category?.toLowerCase())
	}
}

export const getProductionDate = (productionDate, packCode) => {
	if (!packCode) {
		return moment(productionDate)
	}
	let date = moment(productionDate)
	const dayShift = packCode.slice(-2)
	const hour = +date.format('HH')
	let productionDay = packCodeNightShiftIndicatorMap[dayShift]
	if (hour >= 6 && hour < 14) {
		productionDay = packCodeMorningShiftIndicatorMap[dayShift]
	} else if (hour >= 14 && hour < 22) {
		productionDay = packCodeEveningShiftIndicatorMap[dayShift]
	} else {
		if (hour >= 0 && hour < 6) {
			date = date.subtract(1, 'day')
			productionDay = packCodeNightShiftIndicatorMap[dayShift]
		} else {
			productionDay = packCodeNightShiftIndicatorMap[dayShift]
		}
	}
	date.set('date', productionDay)
	return date
}

export const getRemainingShelfLife = (date) => {
	const expiryDate = moment(date)
	const currentDate = moment()
	const diff = expiryDate.diff(currentDate, 'days')
	return diff < 0 ? 0 : diff
}

export const getChargeType = (type = '') => {
	switch (type) {
		case ChargeType.DRY:
			return 'Dry'
		case ChargeType.WET:
			return 'Wet Charge'
		default:
			return type
	}
}
